import React, { useMemo, useContext, forwardRef, useImperativeHandle } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Divider,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import "../../assets/css/SIP.css";
import "../../assets/css/ConsumerPackingComponent.scss"
import { CheckCRUDAccess } from "../../helper/GenericFunctions";
import { ProductDataContext } from "../../contexts/productData/ProductDataContext";
import Tooltipcommon from "../../controls/Tooltip";
import { PRODUCT_EVACUATION } from "../../constants/ExperimentalTooltip.constant";
import {PartialDataWarning,PackagingSection,RecyclabilityStatus,useConsumerPackagingContext} from ".";
import calculatingicon from "../../assets/images/calculate.svg";

export interface ConsumerPackagingRef {
  packagingDataSave: () => void;
}
const ConsumerPackaging = forwardRef<ConsumerPackagingRef>((props,ref) => {
  const [errorProduct, setErrorProduct] = React.useState<string | null>(null);
  const {
    handleAddPrimary,
    handleAddSecondary,
    primaryData,
    secondaryData,
    setProductEvacuationValue,
    productEvacuationValue,
    isSaveEnabled,
    handleSavePacking,
    isCalculating,
    primaryRecycleStatus,
    secondaryRecycleStatus,
    setIsSaveEnabled,
    counterPrimary,
    counterSecondary
  } = useConsumerPackagingContext();

  const packagingDataSave = () => {
    handleSavePacking(true);
    console.log(props)
  }
  const isAnyDataIncompletePrimary = primaryData.some(
    (item) => item.isDataComplete === false
  );
  const isAnyDataIncompletesecondary = secondaryData.some(
    (item) => item.isDataComplete === false
  );
  const { usersData } = useContext(ProductDataContext);

  useImperativeHandle(ref, () => ({
    packagingDataSave
  }));

  const validateEvacuation = (value: string) => {
    if (!value || Number(value) === 0) {
      setErrorProduct(
        "Product Evacuation field cannot be blank, please enter a value between 1 - 100%"
      );
    } else if (Number(value) > 100) {
      setErrorProduct(
        "Product Evacuation field cannot be greater than 100%, please enter a value between 1 - 100%."
      );
    } else {
      setErrorProduct("");
      setIsSaveEnabled(true);
    }
  };

  const isProductEvacuationDisabled = useMemo(() => {
    if (primaryData.length === 0) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryData, productEvacuationValue]);

  return (
    <>
      {/** START CODE - PRIMARY - SECONDARY - PACKAGING */}
      {(primaryData.length || secondaryData.length) &&
        (isAnyDataIncompletePrimary || isAnyDataIncompletesecondary) ? (
        <PartialDataWarning />
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="packing-recycle-status-row">
          <RecyclabilityStatus
            status={primaryRecycleStatus}
            packagingType="Primary"
          />
          <RecyclabilityStatus
            status={secondaryRecycleStatus}
            packagingType="Secondary"
          />
        </div>
        <div style={{ width: "120px", textAlign: "right" }}>
          {!isCalculating ?
            <button
              style={{
                width: "110px",
                cursor: isSaveEnabled ? "pointer" : "not-allowed",
                opacity: isSaveEnabled ? 1 : 0.6,
              }}
              className="disabledfield packaging-save-btn"
              onClick={()=>handleSavePacking(false)}
              disabled={!isSaveEnabled}
            >
              {"Calculate"}
            </button> :
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "12px"
            }}>
              <img src={calculatingicon} alt="Calculating" />
              <p className="calculating-text">{"Calculating"}</p>
            </div>
          }
        </div>
      </div>
      {/** END CODE - PRIMARY - SECONDARY - PACKAGING */}

      {/** START CODE - PRIMARY PACKAGING */}

      <div>
        <div className="packaging-main-div">
          <span className="header packaging-main-title">Primary Packaging</span>
          <br />
        </div>
        <br />
        <Divider sx={{ bgcolor: "black" }} />
        <div style={{ width: "auto", display: "flex", marginTop: "25px" }}>
          <div style={{ width: "480px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                fontWeight="700"
                fontSize={"13.33px"}
                sx={{ fontFamily: "kenvue-sans", fontWeight: "700" }}
                variant="subtitle1"
              >
                Number of Primary Packaging Components
              </Typography>
              <Typography
                fontWeight="400"
                fontSize={"13.33px"}
                sx={{ fontFamily: "kenvue-sans-regular" }}
                variant="subtitle1"
                color={"red"}
              >
                *
              </Typography>
            </div>

            <div style={{ width: "100px", marginTop: "10px", display: "flex" }}>
              <div
                style={{ width: "auto", marginTop: "0px", marginLeft: "5px" }}
              >
                <span>{primaryData.length}</span>
              </div>
            </div>
          </div>
          <Tooltip
            title={errorProduct || ""}
            arrow
            placement="right-start"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  color: "black", // Text color
                  backgroundColor: "white", // Background color
                  border: "1px solid black", // Border color
                  borderRadius: "10px 10px 10px 0px",
                  padding: "8px 12px",
                  transform: "translateX(10px) translateY(-10px)", // Position adjustment
                  margin: "0",
                },
              },
            }}
          >
            <div
              style={{
                width: "202px",
                backgroundColor: errorProduct ? "#f8d7da" : "transparent", // Light red background on error
                borderRadius: errorProduct ? "20px" : "0px", // Optional: Add rounded corners for better appearance
                padding: errorProduct ? "10px" : "0px",
                marginTop: errorProduct ? "-12px" : "0px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  fontWeight="700"
                  fontSize={"13.33px"}
                  sx={{ fontFamily: "kenvue-sans", fontWeight: "700" }}
                  variant="subtitle1"
                >
                  Product Evacuation
                </Typography>
                <Typography
                  fontWeight="400"
                  fontSize={"13.33px"}
                  sx={{ fontFamily: "kenvue-sans-regular" }}
                  variant="subtitle1"
                  color={"red"}
                >
                  *
                </Typography>

                <Tooltipcommon
                  content={PRODUCT_EVACUATION}
                  direction="product-evacuation"
                >
                  <InfoIcon />
                </Tooltipcommon>
              </div>
              <div style={{ width: "150px", display: "flex" }}>
                <div style={{ width: "68px" }}>
                  <TextField
                    className="standard-basic"
                    style={{ width: "68px" }}
                    variant="standard"
                    value={productEvacuationValue}
                    onChange={(e) => {
                      validateEvacuation(e.target.value);
                      setProductEvacuationValue(e.target.value);
                    }}
                    disabled={
                      isProductEvacuationDisabled ||
                      CheckCRUDAccess(usersData, "consumer_packaging") === 0
                    }
                    type="number"
                    sx={{
                      "& input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "& input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "& input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            fontWeight="400"
                            fontSize={"13.33px"}
                            sx={{ fontFamily: "kenvue-sans-regular" }}
                          >
                            {productEvacuationValue.trim() === "" ? "" : `%`}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <br />
      <Divider />

      <PackagingSection
        title="Primary"
        counter={counterPrimary}
        components={primaryData}
        onAddComponent={handleAddPrimary}
      />
      {/** END CODE - PRIMARY PACKAGING */}

      {/** START CODE - SECONDARY PACKAGING */}

      <div>
        <div className="packaging-main-div">
          <span className="header packaging-main-title">
            Secondary Packaging
          </span>
          <br />
        </div>
        <br />
        <Divider sx={{ bgcolor: "black" }} />

        <div style={{ width: "auto", display: "flex", marginTop: "25px" }}>
          <div style={{ width: "480px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                fontWeight="700"
                fontSize={"13.33px"}
                sx={{ fontFamily: "kenvue-sans", fontWeight: "700" }}
                variant="subtitle1"
              >
                Number of Secondary Packaging Components
              </Typography>
            </div>

            <div style={{ width: "100px", marginTop: "10px", display: "flex" }}>
              <div
                style={{ width: "auto", marginTop: "0px", marginLeft: "5px" }}
              >
                <span>{counterSecondary}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Divider />
      <PackagingSection
        title="Secondary"
        counter={counterSecondary}
        components={secondaryData}
        onAddComponent={handleAddSecondary}
      />
      {/** END CODE - SECONDARY PACKAGING */}
    </>
  );
});

export default ConsumerPackaging;
