import React from "react";
import { Divider } from "@mui/material";
import leafIcon from "../../../assets/images/leaf_icon_formulation.svg";
import warningIcon from "../../../assets/images/Warning_icon_formulation.svg";
import {
  LEAF_ICON_CONTENT,
  WARNING_ICON_CONTENT,
} from "../../../constants/ExperimentalTooltip.constant";

const FormulationLegends: React.FC = () => {
    const iconLegends=[
        {
            icon:leafIcon,
            content:LEAF_ICON_CONTENT
        },{
            icon:warningIcon,
            content:WARNING_ICON_CONTENT
        }
    ]
  return (
    <div className="table-bottom-color-band-formulation">
      <div className="color-band-left-empty"></div>
      <div className="color-band-div">
        <div className="color-key">Color Key</div>
        <div className="color-band">
          <div className="color-band-1"></div>
          <div className="color-band-2"></div>
          <div className="color-band-3"></div>
        </div>
        <div className="color-band-text">
          <div className="color-band-text-1">
            <div className="div-second-child" style={{ textAlign: "left" }}>
              Low
            </div>
          </div>
          <div className="color-band-text-2"></div>
          <div className="color-band-text-3">
            <div className="div-second-child" style={{ textAlign: "right" }}>
              High
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <div className="icon-key-section">
            <div className="Icon-key">Icon Key</div>
            {iconLegends.map((item)=>(
                <div className="icon-content" key={item.content}>
                <img src={item.icon} alt="SIP" />
                <div>{item.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormulationLegends;
