import React, { createContext, useState, useEffect, useMemo } from "react";
import {
  useGetBaselineTableResults,
  useGetProductAssessmentResultByID,
} from "../../hooks/UseGetProductDetails";
import {
  ResultDataType,
  ResultContextProp,
  ResultProviderProps,
  FootprintStructure,
  ISustainableStructure,
  IPEFData,
  IFootprintData,
  IRawMaterial,
  IEolRawMaterial,
  IGreenChemistryStructure,
} from "../../structures/result";
import {
  getRawMaterialDataFormulation,
  extractDialData,
  calculateFootprintTabs,
  getRawMaterialDataGCDetailedResult,
} from "../../helper/GenericFunctions";
import {
  ComponentDetail,
  Component,
  IPackagingLevelObject,
  IComponentLevelObject,
  IComponentMaterialObject,
  PackagingComponentData,
} from "../../structures/packaging";
import {
  ASSESSMENT_TYPE,
  CURRENT_TAB,
  GREEN_CHEMISTRY_SECTIONS,
  SUSTAINABLE_SECTIONS,
} from "../../constants/String.constants";

// helper object
const pefLifeCycleStateHelper: { [key: string]: string } = {
  "Raw Material \n Production": "raw_material_production_functional_unit",
  "Packaging \nProduction": "pack_production_functional_unit",
  "Finished Product \n Manufacturing": "manufacturing_functional_unit",
  "Storage & \nDistribution": "distribution_functional_unit",
  "Consumer Use": "use_phase_functional_unit",
  "Formula End-\n    -of-Life": "formula_eol_functional_unit",
  "Packaging \nEnd-of Life": "pack_eol_functional_unit",
};

// helper object for carbon footprint barchart
const CF_pefLifeCycleStateHelper: { [key: string]: string } = {
  "Raw Material \n Production": "raw_material_production",
  "Packaging \nProduction": "pack_production",
  "Finished Product \n Manufacturing": "manufacturing",
  "  Storage & \nDistribution": "distribution",
  "Consumer \n Use": "use_phase",
  "Formula \nEnd-of-Life": "formula_eol",
  "Packaging \nEnd-of Life": "pack_eol",
};

// Separate function to update a single footprint item
const updateFootprint = (
  item: IFootprintData,
  rawMatId: string,
  envFootprintValue: number,
  carbonFootprintValue: number
): IFootprintData => {
  if (item.rawMaterialId === rawMatId) {
    return {
      ...item,
      envFootprint: envFootprintValue,
      carbonFootprint: carbonFootprintValue,
    };
  }
  return { ...item };
};

// Separate function to create the updated footprint array
const getUpdatedFootprints = (
  prevValues: IFootprintData[],
  rawMatId: string,
  envFootprintValue: number,
  carbonFootprintValue: number
): IFootprintData[] => {
  return prevValues.map((item) =>
    updateFootprint(item, rawMatId, envFootprintValue, carbonFootprintValue)
  );
};
const defaultDials = {
  PieChartJSONSeries1: [
    {
      actaulRangeIndicator: "",
      colors_series1: "",
      dialsIndicator: "",
      rangeIndicator: 0,
      data_series1: "",
    },
    {
      actaulRangeIndicator: "",
      colors_series1: "",
      dialsIndicator: "",
      rangeIndicator: 0,
      data_series1: "",
    },
  ],
  pie_chart_percentage: "0",
  pie_chart_sub_title: "",
  total_lifecycle_total_pef_excluding_use_phase_functional_unit: 0,
};

const defaultTabs = {
  totalProduct: { percentage: 0, myproduct: 0, baseline: 0 },
  formulation: { percentage: 0, myproduct: 0, baseline: 0 },
  packaging: { percentage: 0, myproduct: 0, baseline: 0 },
};

const defaultSustainableTabs = {
  totalScore: { heading: "Total Score", percentage: 30 },
  pcrContent: {
    heading: "PCR Content",
    percentage: "0",
    myproduct: "0",
    baseline: "0",
  },
  materialEfficiency: {
    heading: "Material Efficiency",
    percentage: "0",
    myproduct: "0",
    baseline: "0",
  },
  recycleReady: {
    heading: "Recycle Ready",
    percentage: "0",
    myproduct: "23",
    baseline: "32",
  },
  disruptors: {
    heading: "Recyclability Disruptors",
    percentage: "Pass",
    myproduct: "Pass",
    baseline: "Pass",
  },
};
const defaultGreenChemistryTabs = {
  totalScore: {
    heading: "Total Score",
    percentage: "0",
    myproduct: "0",
    baseline: "0",
  },
  gaiaScore: {
    heading: "GAIA Score",
    percentage: "0",
    myproduct: "0",
    baseline: "0",
  },
  watchListScore: {
    heading: "Watch List Score",
    percentage: "0",
    myproduct: "0",
    baseline: "0",
  },
  renewableOriginBonus: {
    heading: "Renewable Origin Bonus",
    percentage: "0",
    myproduct: "0",
    baseline: "0",
  },
};

// Initial state templates
const initialFootprintStructure = {
  totalProduct: { barData: [] },
  formulation: [],
  packaging: {},
  dials: defaultDials,
  tabs: defaultTabs,
};

const initialSustainablePackagingData = {
  pcrContent: {
    dialData: { baseline: "0", myproduct: "0", per_pcr_diff: "0" },
    pcrTableData: {},
  },
  materialEfficiency: {
    barData: { baseline: "0", myproduct: "0" },
    detailedData: [],
  },
  recycleReady: { barData: [], detailedData: [] },
  disruptors: { baselineProduct: {}, myproduct: {}, watchOut: "" },
  dials: defaultDials,
  tabs: defaultSustainableTabs,
};
const initialGreenChemistryData = {
  renewableOriginBonus: {
    dialData: { baseline: "0", myproduct: "0", per_pcr_diff: "0" },
    robTableData: [{
      rawMaterialTradeName:"",
      rawCode: "",
      baselineWeight: "0.00",
      baselineOrganic: "0.00",
      baselineRenewable: "0.00",
      myProductWeight: "0.00",
      myProductOrganic: "0.00",
      myProductRenewable: "0.00"
    }],
    totalPercent:{baselineOrganic:'0.00',baselineRenewable:'0.00',myproductOrganic:'0.00',myproductRenewable:'0.00'},
    regression:false
  },
  dials: defaultDials,
  tabs: defaultGreenChemistryTabs,
  watchList: { baselineData: {}, myProductData: {}, max_watchlist_score_baseline: '0', max_watchlist_score_myproduct: '0' },
  gaiaScore: {baselineData: {}, myProductData: {}}
};

export const ResultDataContext = createContext<ResultContextProp>({
  resultData: null,
  productEnvironmentalFootprintData: initialFootprintStructure,
  carbonFootprintData: initialFootprintStructure,
  sustainablePackagingData: initialSustainablePackagingData,
  greenChemistryData: initialGreenChemistryData,
  assessmentsType: null,
  setCurrentTab: () => {},
  setCurrentSustainableSection: () => {},
  currentGreenChemistrySection: "",
  setCurrentGreenChemistrySection: () => {},
  footPrintData: [],
  currentTab: "",
  currentSustainableSection: "",
  resultDataRefetch: () => {},
  refetchResultBaseline: () => {},
});

export const ResultDataProvider: React.FC<ResultProviderProps> = ({
  children,
  assessmentType,
  productId,
  assessmentId,
}) => {
  const resultkey = `${assessmentType.toLowerCase()}` as const;
  const [resultData, setResultData] = useState<ResultDataType | null>(null);
  const [currentTab, setCurrentTab] = useState<string>(
    "PRODUCT_ENVIRONMENTAL_FOOTPRINT"
  );
  const [currentSustainableSection, setCurrentSustainableSection] =
    React.useState<string>(SUSTAINABLE_SECTIONS.TOTAL_SCORE);
  const [currentGreenChemistrySection, setCurrentGreenChemistrySection] =
    React.useState<string>(GREEN_CHEMISTRY_SECTIONS.TOTAL_SCORE);
  const [
    productEnvironmentalFootprintData,
    setProductEnvironmentalFootprintData,
  ] = useState<FootprintStructure>(initialFootprintStructure);

  const [carbonFootprintData, setCarbonFootprintData] =
    useState<FootprintStructure>(initialFootprintStructure);

  const [sustainablePackagingData, setSustainablePackagingData] =
    useState<ISustainableStructure>(initialSustainablePackagingData);
  const [greenChemistryData, setGreenChemistryData] =
    useState<IGreenChemistryStructure>(initialGreenChemistryData);
  const [footPrintData, setFootPrintData] = useState<IFootprintData[]>([]);

  const { data, refetch: resultDataRefetch } =
    useGetProductAssessmentResultByID(productId, assessmentId, assessmentType);

  const { data: resultBaseline, refetch: refetchResultBaseline } =
    useGetBaselineTableResults(productId, assessmentId, "baseline");

  useEffect(() => {
    setCurrentSustainableSection(SUSTAINABLE_SECTIONS.TOTAL_SCORE);
    setCurrentGreenChemistrySection(GREEN_CHEMISTRY_SECTIONS.TOTAL_SCORE);
  }, [currentTab]);
  useEffect(() => {
    if (resultDataRefetch) {
      resultDataRefetch();
    }
    if (refetchResultBaseline) {
      refetchResultBaseline();
    }
  }, [
    assessmentId,
    assessmentType,
    productId,
    resultDataRefetch,
    refetchResultBaseline,
  ]);

  useEffect(() => {
    setResultData(data);
  }, [data]);

  useEffect(() => {
    if (data) {
      // Extract product environmental footprint data
      const productEnvironmentalFootprint = getRawMaterialDataFormulation(
        data[resultkey],
        data?.baseline,
        1000000,
        "productEnvironmental"
      );
      

      // Set product environmental footprint data
      setProductEnvironmentalFootprintData((prev) => ({
        ...prev,
        formulation: productEnvironmentalFootprint,
        dials: extractDialData(
          data[resultkey]?.totallca,
          data?.baseline?.totallca,
          "productEnvironmental"
        ),
      }));

      // Extract carbon footprint data similarly
      const carbonFootprint = getRawMaterialDataFormulation(
        data[resultkey],
        data?.baseline,
        1000,
        "carbonFootprint"
      );
      setProductEnvironmentalFootprintData((prev) => ({
        ...prev,
        formulation: carbonFootprint,
        dials: extractDialData(
          data[resultkey]?.totallca,
          data?.baseline?.totallca,
          "carbonFootprint"
        ),
      }));

      const dialPEFPercentage = extractDialData(
        data?.[assessmentType]?.totallca ?? {},
        data?.baseline?.totallca ?? {},
        "productEnvironmental"
      );

      const productEnvironmentalFootprintTabsData = calculateFootprintTabs(
        data,
        assessmentType,
        {
          totalProduct:
            "total_lifecycle_total_pef_excluding_use_phase_functional_unit",
          formulation: "total_formulation_TOTAL_PEF_functional_unit",
          packaging: "total_packaging_TOTAL_PEF_functional_unit",
        },
        1000000 // Multiplier for PEF
      );

      // Update state for product environmental footprint
      setProductEnvironmentalFootprintData((prev) => ({
        ...prev,
        formulation: productEnvironmentalFootprint,
        dials: dialPEFPercentage,
        tabs: productEnvironmentalFootprintTabsData,
      }));

      const dialCFPercentage = extractDialData(
        data?.[assessmentType]?.totallca ?? {},
        data?.baseline?.totallca ?? {},
        "carbonFootprint"
      );

      const carbonFootprintData = calculateFootprintTabs(
        data,
        assessmentType,
        {
          totalProduct:
            "total_lifecycle_pre_normalization_excluding_use_phase.climate_change_functional_unit",
          formulation:
            "total_formulation_pre_normalization.climate_change_functional_unit",
          packaging:
            "total_packaging_pre_normalization.climate_change_functional_unit",
        },
        1000 // Multiplier for Carbon Footprint
      );

      // Update state for carbon footprint
      setCarbonFootprintData((prev) => ({
        ...prev,
        formulation: carbonFootprint,
        dials: dialCFPercentage,
        tabs: carbonFootprintData,
      }));

      const dialSPPercentage = extractDialData(
        data?.[assessmentType] ?? {},
        data?.baseline ?? {},
        "sustainablePackaging"
      );

      // Update state for sustainable packaging
      setSustainablePackagingData((prev) => ({
        ...prev,
        dials: dialSPPercentage,
      }));
    }
  }, [data, assessmentId, resultkey, assessmentType, productId]);

  //BarChart Related States --> Naveen

  useEffect(() => {
    const configurePefData = (
      myProductData: IPEFData,
      baselineData: IPEFData,
      type: string
    ) => {
      const values = Object.keys(pefLifeCycleStateHelper) as Array<
        keyof IPEFData
      >;
      if (currentTab === CURRENT_TAB.PRODUCT_ENVIRONMENTAL_FOOTPRINT) {
        const data = values.map((item) => {
          return {
            lifecyclestage: item,
            myproduct:
              myProductData[pefLifeCycleStateHelper[item] as keyof IPEFData] *
              1000000,
            baseline:
              baselineData[pefLifeCycleStateHelper[item] as keyof IPEFData] *
              1000000,
          };
        });
        if (type === ASSESSMENT_TYPE.EXPERIMENTAL_ASSESSMENT) {
          setProductEnvironmentalFootprintData((prevState) => ({
            ...prevState,
            totalProduct: { barData: data },
          }));
        }
        if (type === ASSESSMENT_TYPE.FINAL_ASSESSMENT) {
          setProductEnvironmentalFootprintData((prevState) => ({
            ...prevState,
            totalProduct: { barData: data },
          }));
        }
      }
      if (currentTab === CURRENT_TAB.CARBON_FOOTPRINT) {
        const data = values.map((item) => {
          return {
            lifecyclestage: item,
            myproduct:
              myProductData[pefLifeCycleStateHelper[item] as keyof IPEFData],
            baseline:
              baselineData[pefLifeCycleStateHelper[item] as keyof IPEFData],
          };
        });
        if (type === ASSESSMENT_TYPE.EXPERIMENTAL_ASSESSMENT) {
          setCarbonFootprintData((prevState) => ({
            ...prevState,
            totalProduct: { barData: data },
          }));
        }
        if (type === ASSESSMENT_TYPE.FINAL_ASSESSMENT) {
          setCarbonFootprintData((prevState) => ({
            ...prevState,
            totalProduct: { barData: data },
          }));
        }
      }
    };
    const handlePefData = (
      myProductData: IPEFData,
      baselineData: IPEFData,
      assessmentType: string
    ) => {
      if (myProductData && baselineData) {
        configurePefData(myProductData, baselineData, assessmentType);
      }
    };

    const getPefData = (assessmentType: string) => {
      const initialValues: IPEFData = {
        raw_material_production_functional_unit: 0,
        pack_production_functional_unit: 0,
        manufacturing_functional_unit: 0,
        distribution_functional_unit: 0,
        use_phase_functional_unit: 0,
        formula_eol_functional_unit: 0,
        pack_eol_functional_unit: 0,
      };

      const myProductData: IPEFData = { ...initialValues };
      const baselineData: IPEFData = { ...initialValues };

      const values = Object.values(CF_pefLifeCycleStateHelper) as Array<
        keyof IPEFData
      >;

      if (assessmentType === ASSESSMENT_TYPE.EXPERIMENTAL_ASSESSMENT) {
        values.forEach((item) => {
          const value =
            data?.experimental?.totallca?.pre_normalization[item]
              ?.climate_change_functional_unit * 1000;
          myProductData[item + "_functional_unit"] = value;
        });
      } else if (assessmentType === ASSESSMENT_TYPE.FINAL_ASSESSMENT) {
        values.forEach((item) => {
          const value =
            data?.final?.totallca?.pre_normalization[item]
              ?.climate_change_functional_unit * 1000;
          myProductData[item + "_functional_unit"] = value;
        });
      }

      if (data?.isBaselinePresent) {
        values.forEach((item) => {
          const value =
            data.baseline?.totallca?.pre_normalization[item]
              ?.climate_change_functional_unit * 1000;
          baselineData[item + "_functional_unit"] = value;
        });
      }
      return { myProductData, baselineData };
    };

    if (currentTab === CURRENT_TAB.PRODUCT_ENVIRONMENTAL_FOOTPRINT) {
      if (data?.isBaselinePresent) {
        let myProductData, baselineData;
        if (assessmentType === ASSESSMENT_TYPE.EXPERIMENTAL_ASSESSMENT) {
          myProductData = data?.experimental?.totallca?.total_pef;
          baselineData = data?.baseline?.totallca?.total_pef;
        } else if (assessmentType === ASSESSMENT_TYPE.FINAL_ASSESSMENT) {
          myProductData = data?.final?.totallca?.total_pef;
          baselineData = data?.baseline?.totallca?.total_pef;
        }
        handlePefData(myProductData, baselineData, assessmentType);
      }
    }

    if (currentTab === CURRENT_TAB.CARBON_FOOTPRINT) {
      const { myProductData, baselineData } = getPefData(assessmentType);
      handlePefData(myProductData, baselineData, assessmentType);
    }
  }, [resultData, assessmentType, currentTab, assessmentId, data]);

  const capitalizeFirstLetter = (value: string) => {
    return value
      ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      : "N/A";
  };
  // Consumer Packing ----> Naveen
  // This useEffect need to be optimised ---> Commented by Naveen ; code written by Naveen
  useEffect(() => {
    // Initialize arrays to hold baseline and product component data
    const baselineComponentData: Component[] = [];
    const productComponentData: Component[] = [];

    // Helper function to build component comparison object
    const buildComponentComparison = (
      component: IComponentLevelObject,
      isBaseline: boolean
    ): Component => {
      const componentWeight = parseFloat(component?.weight || "0");
      const componentWeightDose = parseFloat(
        component?.material_efficiency || "0"
      );

      const componentPCRContent = parseFloat(
        component?.PCR_Percent_Per_Component || "0"
      );
      // Determine the environmental footprint value based on the tab and material data
      const getEnvironmentalFootprint = (
        currentTab: string,
        material: IComponentMaterialObject
      ): number => {
        const environmentalFootprint =
          currentTab === CURRENT_TAB.PRODUCT_ENVIRONMENTAL_FOOTPRINT
            ? material?.productEnvironmentalFootPrint
            : material?.carbonFootPrint;

        return parseFloat(environmentalFootprint || "0");
      };

      const details = component.material.map(
        (material: IComponentMaterialObject) => {
          const baselineEnvironmentalFootprint = getEnvironmentalFootprint(
            currentTab,
            material
          );
          const myProductEnvironmentalFootprint = getEnvironmentalFootprint(
            currentTab,
            material
          );

          return {
            materialName: material?.material_name,
            convertingProcess: material?.converting_process,
            finishingProcess: component?.finishing_process,
            materialType: material?.material_type,
            baselineWeight: isBaseline ? material?.material_pct : null,
            myProductWeight: isBaseline ? null : material?.material_pct,
            baselineMaterialWeight: isBaseline
              ? material?.material_weight
              : null,
            myProductMaterialWeight: isBaseline
              ? null
              : material?.material_weight,
            baselineMaterialWeightDose: isBaseline
              ? material?.material_efficiency
              : null,
            myProductMaterialWeightDose: isBaseline
              ? null
              : material?.material_efficiency,
            baselineMaterialPCRContent: isBaseline
              ? material?.PCR_Material_Percent_Component
              : null,
            myProductMaterialPCRContent: isBaseline
              ? null
              : material?.PCR_Material_Percent_Component,
            baselineEnvironmentalFootprint,
            myProductEnvironmentalFootprint,
          };
        }
      );
      return {
        componentName: component?.component_type,
        componentWeight: isBaseline ? componentWeight.toString() : null,
        myProductComponentWeight: !isBaseline ? componentWeight : null,
        baseLineComponentWeight: isBaseline ? componentWeight : null,
        myProductComponentWeightDose: !isBaseline ? componentWeightDose : null,
        baselineComponentWeightDose: isBaseline ? componentWeightDose : null,
        myProductComponentPCRContent: !isBaseline ? componentPCRContent : null,
        baselineComponentPCRContent: isBaseline ? componentPCRContent : null,
        details,
      };
    };

    if (
      currentTab === CURRENT_TAB.PRODUCT_ENVIRONMENTAL_FOOTPRINT ||
      currentTab === CURRENT_TAB.CARBON_FOOTPRINT
    ) {
      // Build baseline component data
      data?.baselinePackaging?.packaging_level?.forEach(
        (baselineLevel: IPackagingLevelObject) => {
          baselineLevel?.components?.forEach(
            (component: IComponentLevelObject) => {
              baselineComponentData.push(
                buildComponentComparison(component, true)
              );
            }
          );
        }
      );
      // Build product component data
      data?.myProductPackaging?.packaging_level?.forEach(
        (productPackingLevel: IPackagingLevelObject) => {
          productPackingLevel?.components?.forEach(
            (component: IComponentLevelObject) => {
              productComponentData.push(
                buildComponentComparison(component, false)
              );
            }
          );
        }
      );
    } else if (currentTab === CURRENT_TAB.SUSTAINABLE_PACKAGING) {
      if (
        currentSustainableSection === SUSTAINABLE_SECTIONS.MATERIAL_EFFICIENCY
      ) {
        data?.baseline?.["sustainablepackaging-material-efficiency"]?.components?.forEach(
          (component: IComponentLevelObject) => {
            baselineComponentData.push(
              buildComponentComparison(component, true)
            );
          }
        );

        // Build product component data
        data?.[resultkey]?.["sustainablepackaging-material-efficiency"]?.components?.forEach(
          (component: IComponentLevelObject) => {
            productComponentData.push(
              buildComponentComparison(component, false)
            );
          }
        );
      } else {
        data?.baseline?.["sustainablepackaging-pcr"]?.components?.forEach(
          (component: IComponentLevelObject) => {
            baselineComponentData.push(
              buildComponentComparison(component, true)
            );
          }
        );

        // Build product component data
        data?.[resultkey]?.["sustainablepackaging-pcr"]?.components?.forEach(
          (component: IComponentLevelObject) => {
            productComponentData.push(
              buildComponentComparison(component, false)
            );
          }
        );
      }
    }
    const findBaselineComponent = (
      componentName: string,
      materialName: string
    ) => {
      const checkingComponent = baselineComponentData?.find(
        (item: Component) => {
          return item.componentName === componentName ? item : null;
        }
      );
      if (checkingComponent) {
        const baselineInfo = checkingComponent?.details?.find(
          (matDetails: ComponentDetail) => {
            return matDetails.materialName === materialName;
          }
        );
        return baselineInfo;
      }
    };

    const findMyProductComponent = (
      componentName: string,
      materialName: string
    ) => {
      const checkingComponent = productComponentData?.find(
        (item: Component) => {
          return item.componentName === componentName ? item : null;
        }
      );
      if (checkingComponent) {
        const myProductInfo = checkingComponent?.details?.find(
          (matDetails: ComponentDetail) => {
            return matDetails.materialName === materialName;
          }
        );
        return myProductInfo;
      }
    };

    const finalConsumerDetails: Component[] = [];
    productComponentData.forEach((component: Component) => {
      const componentName = component.componentName;
      const componentWeight = component.componentWeight;
      const myProductComponentWeight = component?.myProductComponentWeight ?? null;
       
      const myProductComponentWeightDose =
        component?.myProductComponentWeightDose
        ?? null;
      const myProductComponentPCRContent =
        component?.myProductComponentPCRContent
        ?? null;
      const detailsData: ComponentDetail[] = [];
      component.details.forEach((matDetails: ComponentDetail) => {
        const baselineInfo: ComponentDetail | undefined = findBaselineComponent(
          componentName,
          matDetails?.materialName
        );
        detailsData.push({
          ...matDetails,
          baselineWeight: baselineInfo?.baselineWeight,
        });
      });
      finalConsumerDetails.push({
        componentName: componentName,
        componentWeight,
        myProductComponentWeight,
        myProductComponentWeightDose,
        myProductComponentPCRContent,
        details: detailsData,
      });
    });
    baselineComponentData.forEach((component: Component) => {
      const componentName = component.componentName;
      const componentWeight = component.componentWeight;
      const baseLineComponentWeight = component?.baseLineComponentWeight
      ?? null;
      const baselineComponentWeightDose = component?.baselineComponentWeightDose
      ?? null;
      const baselineComponentPCRContent = component?.baselineComponentPCRContent
      ?? null;
      const detailsData: ComponentDetail[] = [];
      component.details.forEach((matDetails: ComponentDetail) => {
        const myProductInfo = findMyProductComponent(
          componentName,
          matDetails?.materialName
        );
        detailsData.push({
          ...matDetails,
          myProductWeight: myProductInfo?.myProductWeight,
        });
      });
      finalConsumerDetails.push({
        componentName: componentName,
        componentWeight,
        baseLineComponentWeight,
        baselineComponentWeightDose,
        baselineComponentPCRContent,
        details: detailsData,
      });
    });
    const mergeComponentDetails = (details: ComponentDetail[]) => {
      const mergedDetails: ComponentDetail[] = [];
      details.forEach((detail: ComponentDetail) => {
        const exsistingDetail = findExistingDetail(mergedDetails, detail);
        if (exsistingDetail) {
          updateExistingDetail(exsistingDetail, detail);
        } else {
          mergedDetails.push(detail);
        }
      });

      return mergedDetails;
    };

    const findExistingDetail = (
      mergedDetails: ComponentDetail[],
      detail: ComponentDetail
    ) => {
      return mergedDetails.find(
        (d) =>
          d.materialName === detail.materialName &&
          d.materialType === detail.materialType &&
          d.convertingProcess === detail.convertingProcess
      );
    };

    const updateExistingDetail = (
      existingDetail: ComponentDetail,
      detail: ComponentDetail
    ) => {
      existingDetail.baselineEnvironmentalFootprint =
        detail.baselineEnvironmentalFootprint ||
        existingDetail.baselineEnvironmentalFootprint;
      existingDetail.myProductEnvironmentalFootprint =
        detail.myProductEnvironmentalFootprint ||
        existingDetail.myProductEnvironmentalFootprint;
      existingDetail.convertingProcess = detail.convertingProcess;
      existingDetail.finishingProcess = existingDetail.finishingProcess
        ? existingDetail.finishingProcess
        : detail.finishingProcess;

      existingDetail.materialName =existingDetail.materialName ?? detail.materialName;

      existingDetail.materialType =
        existingDetail.materialType ?? detail.materialType;

      existingDetail.baselineWeight =
        existingDetail.baselineWeight ?? detail.baselineWeight;

      existingDetail.baselineMaterialWeight =
        existingDetail.baselineMaterialWeight ??  detail.baselineMaterialWeight;

      existingDetail.baselineMaterialWeightDose =
        existingDetail.baselineMaterialWeightDose ??  detail.baselineMaterialWeightDose;

      existingDetail.baselineMaterialPCRContent =
        existingDetail.baselineMaterialPCRContent ?? detail.baselineMaterialPCRContent;

      existingDetail.myProductMaterialWeight =
        existingDetail.myProductMaterialWeight ?? detail.myProductMaterialWeight;

      existingDetail.myProductMaterialWeightDose =
        existingDetail.myProductMaterialWeightDose ?? detail.myProductMaterialWeightDose;

      existingDetail.myProductMaterialPCRContent =
        existingDetail.myProductMaterialPCRContent ?? detail.myProductMaterialPCRContent;

      existingDetail.myProductWeight =
        existingDetail.myProductWeight ?? detail.myProductWeight;
    };
    // Merge components based on the component name and details
    const mergedFinalConsumer = Object.values(
      finalConsumerDetails.reduce(
        (acc: { [key: string]: Component }, item: Component) => {
          if (!acc[item.componentName]) {
            acc[item.componentName] = { ...item };
          } else {
            const mergedDetails = mergeComponentDetails([
              ...acc[item.componentName].details,
              ...item.details,
            ]);
            acc[item.componentName].details = mergedDetails;
          }
          if (
            acc[item.componentName].myProductComponentWeight == null &&
            item.myProductComponentWeight != null
          ) {
            acc[item.componentName].myProductComponentWeight =
              item.myProductComponentWeight;
          }
          if (
            acc[item.componentName].baseLineComponentWeight == null &&
            item.baseLineComponentWeight != null
          ) {
            acc[item.componentName].baseLineComponentWeight =
              item.baseLineComponentWeight;
          }
          if (
            acc[item.componentName].myProductComponentWeightDose == null &&
            item.myProductComponentWeightDose != null
          ) {
            acc[item.componentName].myProductComponentWeightDose =
              item.myProductComponentWeightDose;
          }
          if (
            acc[item.componentName].baselineComponentWeightDose == null &&
            item.baselineComponentWeightDose != null
          ) {
            acc[item.componentName].baselineComponentWeightDose =
              item.baselineComponentWeightDose;
          }

          if (
            acc[item.componentName].myProductComponentPCRContent == null &&
            item.myProductComponentPCRContent != null
          ) {
            acc[item.componentName].myProductComponentPCRContent =
              item.myProductComponentPCRContent;
          }
          if (
            acc[item.componentName].baselineComponentPCRContent == null &&
            item.baselineComponentPCRContent != null
          ) {
            acc[item.componentName].baselineComponentPCRContent =
              item.baselineComponentPCRContent;
          }

          return acc;
        },
        {}
      )
    );
    const disruptorsBaselineData =
      data?.baseline?.["sustainablepackaging-recyclability-disruptors"]?.components.map(
        (component: PackagingComponentData) => ({
          component_type: component.component_type,
          recyclability_disruptors_list_formatted_4_5:
            component.recyclability_disruptors_list_formatted_4_5,
        })
      );
    const disruptorsMyProductData = data?.[
      resultkey
    ]?.["sustainablepackaging-recyclability-disruptors"]?.components.map(
      (component: PackagingComponentData) => ({
        component_type: component.component_type,
        recyclability_disruptors_list_formatted_4_5:
          component.recyclability_disruptors_list_formatted_4_5,
      })
    );
    // Set the final merged data
    setProductEnvironmentalFootprintData((prevValues) => ({
      ...prevValues,
      packaging: { consumerPackaging: mergedFinalConsumer },
    }));

    // Set data for GREEN CHEMISTRY

    const GCDetailedResultData = getRawMaterialDataGCDetailedResult(
      data?.[resultkey]?.renewable_feedback_stock,
      data?.baseline?.renewable_feedback_stock,
      "greenChemistry"
    );
    const formatPercentage = (value) => (value ? `${parseFloat(value).toFixed(1)}%` : "0%");
    const formatPoints = (value) => (value ? `${Math.round(value)} points` : "0");
    const safeNumber = (value) => parseFloat(value || 0);
    
  // Calculate myproduct and baseline scores for watchListScore
    const myProductScore = parseFloat(data?.[resultkey]?.watchlist?.watchlist_score) || 0;
    const baselineScore = parseFloat(data?.baseline?.watchlist?.watchlist_score) || 0;
    const myProductGaiaScore = data?.[resultkey]?.gaia_score?.step_8_fml_GAIA_score || 0;
    const baselineGaiaScore = data?.baseline?.gaia_score?.step_8_fml_GAIA_score || 0;
    const baselineGC = (data?.baseline?.renewable_feedback_stock?.renewable_feedstock_total)*100;
    const myproductGC = (data?.[resultkey]?.renewable_feedback_stock?.renewable_feedstock_total)*100;
    const per_rob_diff_GC = (myproductGC - baselineGC).toFixed(1);
    const per_gaia_score = ((myProductGaiaScore - baselineGaiaScore)).toFixed(2);
    const totalOrganicBaselinePercent =(data?.baseline?.renewable_feedback_stock?.sum_of_organic_chemical_percents ?? 0);
    const totalOrganicMyproductPercent=(data?.[resultkey]?.renewable_feedback_stock?.sum_of_organic_chemical_percents ?? 0);
    const myproducttotalscore = (data?.[resultkey]?.green_chemistry_rollup?.step_6_final_score_with_5_watchlist ?? 0).toFixed(2);

    const baselinetotalscore = (data?.baseline?.green_chemistry_rollup?.step_6_final_score_with_5_watchlist ?? 0).toFixed(2);
    const baselineTotalScore=(data?.baseline?.green_chemistry_rollup?.step_5_final_score ?? 0).toFixed(2);
    const gaiaRegression = data?.[resultkey]?.["green_chemistry_rollup"]?.step_1_GAIA_regression ?? 0;
    const watchlistRegression = data?.[resultkey]?.["green_chemistry_rollup"]?.step_2_watchlist_regression ?? 0;
    const regression = gaiaRegression <= -5 || watchlistRegression > 0;

    let GCtotalScore = parseFloat(((myproducttotalscore - baselinetotalscore)).toFixed(0));
    
    GCtotalScore = isNaN(GCtotalScore)
    ? 0
    : GCtotalScore;

    const dialGCPercentage = extractDialData(
      data?.[resultkey] ?? {},
      data?.baseline ?? {},
      "greenChemistry"
    );
    setGreenChemistryData((prevGCData) => ({
      ...prevGCData,
      renewableOriginBonus: {
        ...prevGCData.renewableOriginBonus,
        dialData: {
          baseline: formatPercentage(baselineGC),
          myproduct: formatPercentage(myproductGC),
          per_pcr_diff: per_rob_diff_GC.toString()+'%' || "0%",
        },
        robTableData: GCDetailedResultData,
        totalPercent:{baselineOrganic:totalOrganicBaselinePercent,baselineRenewable:formatPercentage(baselineGC),myproductOrganic:totalOrganicMyproductPercent,myproductRenewable:formatPercentage(myproductGC)},
        regression,
      },
      tabs: {
        ...prevGCData.tabs,
        renewableOriginBonus: {
          ...prevGCData.tabs.renewableOriginBonus,
          percentage: per_rob_diff_GC || "0",
          baseline: formatPercentage(baselineGC),
          myproduct: formatPercentage(myproductGC),
        },
        watchListScore: {
          heading: 'Watch List Score',
          percentage: (myProductScore - baselineScore).toFixed(1).toString() || '0', 
          myproduct: safeNumber(myProductScore).toFixed(1),
          baseline: safeNumber(baselineScore).toFixed(1),
        },
          gaiaScore: {
          heading: "GAIA Score",
          percentage: per_gaia_score,
          myproduct: formatPoints(myProductGaiaScore),
          baseline: formatPoints(baselineGaiaScore),
        },
        totalScore: {
          heading: "Total Score",
          percentage: String(GCtotalScore),
          myproduct: myproducttotalscore+' points',
          baseline: baselineTotalScore+' points',
        },
        
      },
      dials: dialGCPercentage,
      watchList: {
        baselineData: data?.baseline?.watchlist?.watchlist_table || [],
        myProductData: data?.[resultkey]?.watchlist?.watchlist_table || [],
        max_watchlist_score_baseline: data?.baseline?.watchlist?.max_watchlist_score || "0",
        max_watchlist_score_myproduct: data?.[resultkey]?.watchlist?.max_watchlist_score || "0",
      },
      gaiaScore: {
        myProductData: data?.[resultkey]?.gaia_score || {},
        baselineData:data?.baseline?.gaia_score || {}
      }
    }));

    setSustainablePackagingData((prevData) => ({
      ...prevData,
      tabs: {
        ...prevData.tabs,
        totalScore: {
          ...prevData.tabs.totalScore,
          percentage:
            data?.[resultkey]?.["sustainablepackaging-rollup-compare"]
              ?.Final_Score_Disrupters ?? 0,
        },
        disruptors: {
          ...prevData.tabs.disruptors,
          percentage: capitalizeFirstLetter(
            data?.[resultkey]?.["sustainablepackaging-recyclability-disruptors"]
              ?.recyclability_disruptors_present_all_packaging_4_3 ?? "N/A"
          ),
          baseline: capitalizeFirstLetter(
            data?.baseline?.["sustainablepackaging-recyclability-disruptors"]
              ?.recyclability_disruptors_present_all_packaging_4_3 ?? "N/A"
          ),
          myproduct: capitalizeFirstLetter(
            data?.[resultkey]?.["sustainablepackaging-recyclability-disruptors"]
              ?.recyclability_disruptors_present_all_packaging_4_3 ?? "N/A"
          ),
        },
        materialEfficiency: {
          ...prevData.tabs.materialEfficiency,
          percentage: data?.[resultkey]?.["sustainablepackaging-rollup-compare"]
            ?.Difference_Material_Efficiency
            ? parseFloat(
                data[resultkey]?.["sustainablepackaging-rollup-compare"]
                  ?.Difference_Material_Efficiency
              )
                .toFixed()
                .toString()
            : "0",
          baseline: data?.baseline?.["sustainablepackaging-material-efficiency"]
            ?.Material_Efficiency_All_Packaging
            ? parseFloat(
                data?.baseline?.["sustainablepackaging-material-efficiency"]
                  .Material_Efficiency_All_Packaging
              )
                .toFixed(2)
                .toString()
            : "0.00",
          myproduct: data?.[resultkey]?.["sustainablepackaging-material-efficiency"]
            ?.Material_Efficiency_All_Packaging
            ? parseFloat(
                data[resultkey]?.["sustainablepackaging-material-efficiency"]
                  .Material_Efficiency_All_Packaging
              )
                .toFixed(2)
                .toString()
            : "0.00",
        },
        pcrContent: {
          ...prevData.tabs.pcrContent,
          percentage: data?.[resultkey]?.["sustainablepackaging-rollup-compare"]
            ?.Difference_PCR_Content
            ? parseFloat(
                data[resultkey]?.["sustainablepackaging-rollup-compare"]
                  ?.Difference_PCR_Content
              )
                .toFixed()
                .toString()
            : "0",
          baseline: data?.baseline?.["sustainablepackaging-pcr"]
            ?.pcr_content_all_packaging
            ? parseFloat(
                data?.baseline?.["sustainablepackaging-pcr"]
                  ?.pcr_content_all_packaging
              )
                .toFixed()
                .toString() + "%"
            : "0%",
          myproduct: data?.[resultkey]?.["sustainablepackaging-pcr"]
            ?.pcr_content_all_packaging
            ? parseFloat(
                data[resultkey]?.["sustainablepackaging-pcr"]
                  ?.pcr_content_all_packaging
              )
                .toFixed()
                .toString() + "%"
            : "0%",
        },
      },
      pcrContent: {
        ...prevData.pcrContent,
        dialData: {
          baseline: data?.baseline?.["sustainablepackaging-pcr"]
            ?.pcr_content_all_packaging
            ? parseFloat(
                data?.baseline?.["sustainablepackaging-pcr"].pcr_content_all_packaging
              ).toFixed(2)
            : "0.00",
          myproduct: data?.[resultkey]?.["sustainablepackaging-pcr"]
            ?.pcr_content_all_packaging
            ? parseFloat(
                data[resultkey]?.["sustainablepackaging-pcr"]
                  .pcr_content_all_packaging
              ).toFixed(2)
            : "0.00",
          per_pcr_diff: data?.[resultkey]?.["sustainablepackaging-rollup-compare"]
            ?.Difference_PCR_Content
            ? parseFloat(
                data[resultkey]?.["sustainablepackaging-rollup-compare"]?.Difference_PCR_Content
              ).toFixed(2)
            : "0.00",
        },
        detailedData: mergedFinalConsumer,
      },
      materialEfficiency: {
        ...prevData.materialEfficiency,
        barData: {
          baseline: data?.baseline?.['sustainablepackaging-material-efficiency']
            ?.Material_Efficiency_All_Packaging
            ? parseFloat(
                data?.baseline?.['sustainablepackaging-material-efficiency']
                  .Material_Efficiency_All_Packaging
              )?.toFixed(2)
            : "0.00",
          myproduct: data?.[resultkey]?.["sustainablepackaging-material-efficiency"]
            ?.Material_Efficiency_All_Packaging
            ? parseFloat(
                data[resultkey]?.["sustainablepackaging-material-efficiency"]
                  .Material_Efficiency_All_Packaging
              )?.toFixed(2)
            : "0.00",
        },
        detailedData: mergedFinalConsumer,
      },

      disruptors: {
        baselineProduct: disruptorsBaselineData,
        myproduct: disruptorsMyProductData,
        watchOut:
          data?.[resultkey]?.["sustainablepackaging-recyclability-disruptors"]
            ?.watchout_message_4_6,
      },
    }));
  }, [
    productId,
    assessmentId,
    assessmentType,
    currentTab,
    resultData,
    data,
    resultkey,
    currentSustainableSection,
    currentGreenChemistrySection,
  ]);

  useEffect(() => {
    const getExpRawMaterial = () => {
      if (assessmentType === ASSESSMENT_TYPE.EXPERIMENTAL_ASSESSMENT) {
        return resultData?.experimental?.rawmaterials?.raw_materials;
      }
      if (assessmentType === ASSESSMENT_TYPE.BASELINE_ASSESSMENT) {
        const baselineResults = resultBaseline as ResultDataType;
        return baselineResults?.baseline?.rawmaterials?.raw_materials;
      }
      if (assessmentType === ASSESSMENT_TYPE.FINAL_ASSESSMENT) {
        return resultData?.final?.rawmaterials?.raw_materials;
      }
    };
    const getExpEolRawMaterial = () => {
      if (assessmentType === ASSESSMENT_TYPE.EXPERIMENTAL_ASSESSMENT) {
        return resultData?.experimental?.formula_end_of_life?.raw_materials;
      }
      if (assessmentType === ASSESSMENT_TYPE.BASELINE_ASSESSMENT) {
        const baselineResults = resultBaseline as ResultDataType;
        return baselineResults?.baseline?.formula_end_of_life?.raw_materials;
      }
      if (assessmentType === ASSESSMENT_TYPE.FINAL_ASSESSMENT) {
        return resultData?.final?.formula_end_of_life?.raw_materials;
      }
    };
    if (resultData) {
      const experimentalRawMaterial = getExpRawMaterial();
      const experimentaleolRawMaterial = getExpEolRawMaterial();
      const rawMaterialArray: string[] = [];
      const data: IFootprintData[] = [];
      experimentalRawMaterial?.forEach((item) => {
        rawMaterialArray.push(item.raw_material_id);
        data.push({
          rawMaterialId: item.raw_material_id,
          envFootprint: 0,
          carbonFootprint: 0,
          greenChemistry: 0,
        });
      });
      setFootPrintData(data);

      if (rawMaterialArray.length > 0) {
        rawMaterialArray.forEach((rawMatId) => {
          const rawMaterialProduction =
            experimentalRawMaterial?.filter(
              (item: IRawMaterial) => item.raw_material_id === rawMatId
            ) || [];
          const eolRawMaterial =
            experimentaleolRawMaterial?.filter(
              (item: IEolRawMaterial) => item.raw_material_id === rawMatId
            ) || [];
          if (rawMaterialProduction || eolRawMaterial) {
            const envFootprintValue =
              (rawMaterialProduction[0]
                ?.raw_material_production_PEF_score_per_functional_unit +
                eolRawMaterial[0]?.eol_raw_mat_PEF_score_functional_unit || 0) *
              1000000;
            const carbonFootprintValue =
              (rawMaterialProduction[0]?.raw_material_ef_total
                ?.climate_change_amount_functional_unit +
                eolRawMaterial[0]?.sum_eol_raw_ef
                  ?.climate_change_amount_functional_unit || 0) * 1000;

            setFootPrintData((prevValues) =>
              getUpdatedFootprints(
                prevValues,
                rawMatId,
                envFootprintValue,
                carbonFootprintValue
              )
            );
          }
        });
      }
    }
  }, [assessmentType, resultData, assessmentId, productId, resultBaseline]);
 
 
  // Recycle ready
  useEffect(() => {
    interface BaselineItem {
      componentType: string;
      packagingLayer: string;
      baselineProduct: {
        weight: string;
        recycleReady: string;
      };
      myProduct: {
        weight: string;
        recycleReady: string;
      };
    }
    
    interface IRRComponentDetails {
      component_type: string;
      packaging_level: string;
      weight: string;
      recyclability_status: string;
    }
    
    // Utility functions at module level
    function formatWeight(weight: string): string {
      if (weight === "") return "";
      return Number(weight)?.toFixed(2).toString();
    }
    
    function getRecycleReadyStatus(status: string): string {
      return status === "Recycle Ready" ? "Yes" : "No";
    }
    
    function createMyProductData(weight: string, recyclabilityStatus: string) {
      return {
        weight: formatWeight(weight),
        recycleReady: getRecycleReadyStatus(recyclabilityStatus)
      };
    }
    
    function matchComponentType(baselineItem: BaselineItem, componentType: string): boolean {
      return baselineItem.componentType === componentType;
    }
    
    function findMatchingBaselineItem(baselineTableData: BaselineItem[], componentType: string): BaselineItem | undefined {
      return baselineTableData.find(item => matchComponentType(item, componentType));
    }
    
    function processMatchedItem(baselineItem: BaselineItem, weight: string, recyclabilityStatus: string): BaselineItem {
      return {
        ...baselineItem,
        myProduct: createMyProductData(weight, recyclabilityStatus)
      };
    }
    
    function processUnmatchedItem(componentType: string, packagingLevel: string, weight: string, recyclabilityStatus: string): BaselineItem {
      return {
        componentType,
        packagingLayer: packagingLevel,
        baselineProduct: { weight: "", recycleReady: "" },
        myProduct: createMyProductData(weight, recyclabilityStatus)
      };
    }
    
    function processBaselineItem(baselineItem: BaselineItem): BaselineItem {
      return {
        ...baselineItem,
        myProduct: { weight: "", recycleReady: "" }
      };
    }
    
    function isComponentMatched(relevantComponents: IRRComponentDetails[], componentType: string): boolean {
      return relevantComponents.some(item => item.component_type === componentType);
    }
    
     // Main processing function
  function getCompleteTableData(
    baselineTableData: BaselineItem[],
    getRelevantComponents: () => IRRComponentDetails[]
  ): BaselineItem[] {
    const relevantComponents = getRelevantComponents() || [];
    const mergedData: BaselineItem[] = [];
  
    // Process relevant components
    for (const component of relevantComponents) {
      const baselineItem = findMatchingBaselineItem(baselineTableData, component.component_type);
      
      if (baselineItem) {
        mergedData.push(processMatchedItem(
          baselineItem,
          component.weight,
          component.recyclability_status
        ));
      } else {
        mergedData.push(processUnmatchedItem(
          component.component_type,
          component.packaging_level,
          component.weight,
          component.recyclability_status
        ));
      }
    }
  
    // Process unmatched baseline items
    for (const baselineItem of baselineTableData) {
      if (!isComponentMatched(relevantComponents, baselineItem.componentType)) {
        mergedData.push(processBaselineItem(baselineItem));
      }
    }
  
    return mergedData;
  }
    // RR barchart
    if (resultData) {
      const baselineRRPercentage =
        resultData?.baseline?.["sustainablepackaging-recyclable-content"]
          ?.percentage_recycle_ready_all_packaging || 0;
      const getRRMyProductPercentage = () => {
        return assessmentType === ASSESSMENT_TYPE.FINAL_ASSESSMENT
          ? resultData?.final?.["sustainablepackaging-recyclable-content"]
              ?.percentage_recycle_ready_all_packaging || 0
          : resultData?.experimental?.["sustainablepackaging-recyclable-content"]
              ?.percentage_recycle_ready_all_packaging || 0;
      };
      const myProductRRPercentage = getRRMyProductPercentage();
      const RRBarData = [
        { category: "baseline", value: baselineRRPercentage },
        { category: "myProduct", value: myProductRRPercentage },
      ];

      //totalroll_up RR_percentage
      const getRelavantTotalRollUpPercentage = () => {
        return assessmentType === ASSESSMENT_TYPE.FINAL_ASSESSMENT
          ? resultData?.final?.["sustainablepackaging-rollup-compare"]
              ?.Difference_Recycle_Ready
          : resultData?.experimental?.["sustainablepackaging-rollup-compare"]
              ?.Difference_Recycle_Ready;
      };

      // RR Detail Table
      const baselineTableData =
      resultData?.baseline?.["sustainablepackaging-recyclable-content"]?.components.map(
        (item) => ({
          componentType: item.component_type,
          packagingLayer: item.packaging_level,
          baselineProduct: {
            weight: item.weight === "" ? "" : Number(item.weight)?.toFixed(2).toString(),
            recycleReady: item.recyclability_status === "Recycle Ready" ? "Yes" : "No",
          },
          myProduct: { weight: "", recycleReady: "" },
        })
      ) || [];
    
    const getRelevantComponents = () => {
      return assessmentType === ASSESSMENT_TYPE.FINAL_ASSESSMENT
        ? resultData?.final?.["sustainablepackaging-recyclable-content"]?.components
        : resultData?.experimental?.["sustainablepackaging-recyclable-content"]?.components;
    };
    
    
    
    // Usage
    const completeTableData = getCompleteTableData(baselineTableData, getRelevantComponents);
    

      setSustainablePackagingData((prevValues) => ({
        ...prevValues,
        recycleReady: {
          ...prevValues.recycleReady,
          barData: RRBarData,
          detailedData: completeTableData,
        },
        tabs: {
          ...prevValues.tabs,
          recycleReady: {
            ...prevValues.tabs.recycleReady,
            baseline: baselineRRPercentage?.toFixed().toString() + "%",
            myproduct: myProductRRPercentage?.toFixed().toString() + "%",
            percentage:
              getRelavantTotalRollUpPercentage()?.toFixed(2)?.toString() + "%",
          },
        },
      }));
    }
  }, [productId, assessmentId, assessmentType, currentTab, resultData, data]);

  const value = useMemo(
    () => ({
      resultData,
      productEnvironmentalFootprintData,
      carbonFootprintData,
      sustainablePackagingData,
      greenChemistryData,
      assessmentsType: assessmentType,
      setCurrentTab,
      setCurrentSustainableSection,
      setCurrentGreenChemistrySection,
      currentGreenChemistrySection,
      footPrintData,
      currentTab,
      currentSustainableSection,
      resultDataRefetch,
      refetchResultBaseline,
    }),
    [
      resultData,
      productEnvironmentalFootprintData,
      carbonFootprintData,
      sustainablePackagingData,
      greenChemistryData,
      assessmentType,
      setCurrentTab,
      setCurrentSustainableSection,
      setCurrentGreenChemistrySection,
      currentGreenChemistrySection,
      footPrintData,
      currentTab,
      currentSustainableSection,
      resultDataRefetch,
      refetchResultBaseline,
    ]
  );

  return (
    <ResultDataContext.Provider value={value}>
      {children}
    </ResultDataContext.Provider>
  );
};
