import React,{useEffect, useMemo, useState} from "react";
import {PostContext,Post} from "./DataContext";
import useFetch from "./useFetch";
import { ApiEndPoints,ApiEndPointsURL } from "../../constants/ApiEndPoints.constant";

export default function DataProvider({ children }: Readonly<React.PropsWithChildren<unknown>>) {
  const apiURLS = ApiEndPointsURL;
  const [loaded, data] = useFetch<Post[]>(
    `${apiURLS}${ApiEndPoints.master_data}`
  );
  const [token,setToken]=useState('')
useEffect(()=>{
 setToken(localStorage.getItem('userDetails')?? '')

},[])
const value = useMemo(() => ({
  loaded,
  globaldata: data,
  formulationData: data ? data[0]?.formulation : {},
  packagingData: data ? data[0]?.packaging : {},
  token: token,
}), [loaded, data, token]);

  return (
    <PostContext.Provider value={value}>
      {children}
    </PostContext.Provider>
  );
}

