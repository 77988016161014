import React from "react";
import { Select, MenuItem } from "@mui/material";
import {SelectFieldProps} from "../breadcrumb/types";
import { truncate } from "../../helper/GenericFunctions";


const SelectField: React.FC<SelectFieldProps> = ({
  value,
  onChange,
  options,
  disabled = false,
}) => {
     // Determine if there is an error based on the value and options
  const hasError = !value || !options.includes(value);

  return (
    <Select
      className="disabledfield"
      sx={{
        width: "100%",
        "& fieldset": {
            border: hasError ? "2px solid red" : "none", // Conditional border based on hasError
          },
        textAlign: "left",
        
        "& .MuiSelect-icon": {
          right: "-5px",
        },
        "& .MuiSelect-select": {
          height: "auto",
          minHeight: "1.4375em",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
          overflow: "hidden",
          padding: "15px 6px",
          paddingRight:'1px !important'
        },
      }}
      displayEmpty
      value={value}
      onChange={onChange}
      disabled={disabled}
      renderValue={(value)=>truncate(value,12)}
    >
      <MenuItem disabled value="">
        Select
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectField;
