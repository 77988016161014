import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import Theme from "./theme/Theme";
import "./assets/css/index.scss";
import Dashboard from "./pages/Dashboard.component";
import { Box } from "@mui/material";
import AllProductDashboard from "./pages/AllDashboard.component";
import ProductDetail from "./components/breadcrumb/ProductDetail.component";
import DataProvider from "./contexts/masterData/DataProvider";
import { getLoggedInUserDetails } from "@consumer/core-login-ui-mf";
import { ProductAssessmentDetailsPage } from "./pages/ProductAssessmentDetailsPage.component";
import { ViewAllResultsPage } from "./pages/ViewAllResultsPage.component";
import { SidebarStateProvider } from "./contexts/sidebarData/SidebarStateContext";
import { RootProps, UserData } from "./components/breadcrumb/types";
import SideBarAction from "./components/dashboard/SidebarAction.component";
import { AutoSaveStateProvider } from "./contexts/autoSaveContext/AutoSaveContext";

const Root = (props: RootProps) => {
  const [userName, setUserName] = useState("");
  const [loggedInUser, setLoggedInUser] = useState<UserData | null>(null);
  const loggedInUserDetails = getLoggedInUserDetails();

  const userDetails = localStorage.getItem("userDetails");

  const token = useMemo(() => {
    return userDetails;
  }, [userDetails]);

  useEffect(() => {
    props?.sipUiMfScreen?.subscribe(
      "consumer-core-login-ui-mf:userdetails",
      (_: string, userData: UserData) => {
        if (userData) {
          setLoggedInUser(userData);
          const loggedInUserName = userData?.mail
            ? userData.mail.split("@")[0]
            : "";
          setUserName(loggedInUserName);
          sessionStorage.setItem("givenName", userData?.givenName);
          sessionStorage.setItem("mail", userData?.mail);
        }
      }
    );
  }, [props, token]);

  useEffect(() => {
    if (!userName) {
      const loggedInUserDetails = getLoggedInUserDetails();
      const updatedUserName = loggedInUserDetails.userName;
      setLoggedInUser(loggedInUserDetails);
      if (updatedUserName) {
        setUserName(updatedUserName);
        sessionStorage.setItem("givenName", loggedInUserDetails?.givenName);
        sessionStorage.setItem("mail", loggedInUserDetails?.mail);
      }
    }
  }, [userName, token]);

  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  // Define a fetcher function that includes the token in the request
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultQueryFn = async ({ queryKey }: any) => {
    const response = await fetch(queryKey[0], {
      headers: {
        Authorization: `Bearer ${token}`, // Use token here
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  };

  // Create a QueryClient and set the default query function
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn, // Set the default query function
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  });

  useEffect(() => {
    if (loggedInUser?.accessToken) {
      localStorage.setItem(
        "userDetails",
        JSON.stringify(loggedInUser.accessToken)
      );
    }
  }, [loggedInUser, loggedInUserDetails]);

  return (
    <React.StrictMode>
      {token ? (
        <DataProvider>
          <AutoSaveStateProvider>
            <SidebarStateProvider>
              <ThemeProvider theme={Theme}>
                <QueryClientProvider client={queryClient}>
                  <BrowserRouter>
                    <Box className="main-root">
                      {token && window.location.pathname !== "/sip/login" && (
                        <SideBarAction mfProps={props?.sipUiMfScreen} />
                      )}
                      <Box component="main" className="dashboard-main">
                        <Routes>
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route
                            path="/allproduct"
                            element={<AllProductDashboard />}
                          />
                          <Route
                            path="/product-detail/:id"
                            element={<ProductDetail />}
                          />
                          <Route
                            path="/my-product-detail/:id"
                            element={<ProductDetail />}
                          />
                          <Route
                            path="/product-assessment/:assessmentId"
                            element={<ProductAssessmentDetailsPage />}
                          />
                          <Route
                            path="/view-all-results/:assessmentId"
                            element={<ViewAllResultsPage />}
                          />
                        </Routes>
                      </Box>
                    </Box>
                  </BrowserRouter>
                  {/* <ReactQueryDevtools initialIsOpen={true} /> */}
                </QueryClientProvider>
              </ThemeProvider>
            </SidebarStateProvider>
          </AutoSaveStateProvider>
        </DataProvider>
      ) : (
        ""
      )}
    </React.StrictMode>
  );
};

export default Root;
