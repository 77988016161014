import React, { useState, useEffect, useContext } from "react";
import PieChartDials from "../common/PieChartDials";
import lock_out_warning from "../../assets/images/lock-out-warning.svg";
import great_job from "../../assets/images/large_great_job.svg";
import dials_without_data from "../../assets/images/dials_without_data.svg";
import dataInCompleteIcon from "../../assets/images/dataInCompleteIcon.svg";
import {
  ProductEnvironmentalFootprint_series0,
  CarbonFootprint_series0,
  GreenChemistry_series0,
  SustainablePackaging_series0,
  ProductEnvironmentalFootprintFlipcardDescription,
  SustainablePackagingFlipcardDescription,
  GreenChemistryFlipcardDescription,
  CarbonFootprintFlipcardDescription,
} from "../../constants/PieChartDials.constant";
import { useNavigate } from "react-router-dom";
import result_arrow_full_small_left from "../../assets/images/result_arrow_full_small_left.svg";
import { ResultDataContext } from "../../contexts/resultData/ResultDataContext";
import { ProductDataContext } from "../../contexts/productData/ProductDataContext";
import {
  DialsResultProductAssessmentProps,
  IPieChartJSONSeries1,
} from "../breadcrumb/types";
import "../../assets/css/ProductAssessment.scss";
import { TrackGoogleAnalyticsEvent } from "./TrackGoogleAnalyticsEvent";
import { useGlobaldata } from "../../contexts/masterData/DataContext";

const DialsResultProductAssessment: React.FC<
  DialsResultProductAssessmentProps
> = (props) => {
  const navigate = useNavigate();
  const { assessmentsData} = useContext(ProductDataContext);

  const {
    productEnvironmentalFootprintData,
    carbonFootprintData,
    sustainablePackagingData,
    greenChemistryData
  } = useContext(ResultDataContext);
  const [dialStatus, setDialStatus] = React.useState("look_out");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { globaldata } = useGlobaldata();
  const loginUserName = React.useMemo(() => {
    return (
      sessionStorage.getItem("givenName") ||
      globaldata?.[0]?.loggedInUserDetails?.givenName
    );
  }, [globaldata]);
  const handleCardClick = async (assessmentsData) => {
    // Prevent navigation if there's a text selection
    if (window.getSelection().toString()) {
      return;
    }
  
    // Construct the navigation path
    const pagePath = `/view-all-results/${assessmentsData.assessmentId}`;
  
    // Track the analytics event
    await TrackGoogleAnalyticsEvent("PAGE_VIEW", "View All Results", {
      loginUserName,
      PAGE_VIEW: pagePath,
    });
  
    // Navigate to the page
    navigate(pagePath);
  };
  
  const [
    productEnvironmentalFootprintSeries1,
    setProductEnvironmentalFootprintSeries1,
  ] = useState<IPieChartJSONSeries1[]>(
    productEnvironmentalFootprintData?.dials?.PieChartJSONSeries1
  );

  const [carbonFootprintSeries1, setCarbonFootprintSeries1] = useState<
    IPieChartJSONSeries1[]
  >(carbonFootprintData?.dials?.PieChartJSONSeries1);

  const [sustainablePackagingSeries1, setSustainablePackagingSeries1] =
    useState<IPieChartJSONSeries1[]>(
      carbonFootprintData?.dials?.PieChartJSONSeries1
    );
    const [greenChemistrySeries1, setGreenChemistrySeries1] =
    useState<IPieChartJSONSeries1[]>(
      greenChemistryData?.dials?.PieChartJSONSeries1
    );

  useEffect(() => {
    if (productEnvironmentalFootprintData?.dials?.PieChartJSONSeries1) {
      setProductEnvironmentalFootprintSeries1(
        productEnvironmentalFootprintData.dials.PieChartJSONSeries1
      );
    }
  }, [
    productEnvironmentalFootprintSeries1,
    productEnvironmentalFootprintData.dials.PieChartJSONSeries1,
  ]);

  useEffect(() => {
    if (carbonFootprintData?.dials?.PieChartJSONSeries1) {
      setCarbonFootprintSeries1(carbonFootprintData?.dials?.PieChartJSONSeries1);
    }
  }, [carbonFootprintSeries1, carbonFootprintData?.dials?.PieChartJSONSeries1]);

  useEffect(() => {
    if (sustainablePackagingData?.dials?.PieChartJSONSeries1) {
      setSustainablePackagingSeries1(
        sustainablePackagingData?.dials?.PieChartJSONSeries1
      );
    }
  }, [
    sustainablePackagingSeries1,
    sustainablePackagingData?.dials?.PieChartJSONSeries1,
  ]);
  useEffect(() => {
    if (greenChemistryData?.dials?.PieChartJSONSeries1) {
      setGreenChemistrySeries1(
        greenChemistryData?.dials?.PieChartJSONSeries1
      );
    }
  }, [
    greenChemistrySeries1,
    greenChemistryData?.dials?.PieChartJSONSeries1,
  ]);
  useEffect(() => {
    // Extracting the scores from data for better readability
    const scores = [
      productEnvironmentalFootprintData?.dials?.pie_chart_sub_title,
      carbonFootprintData?.dials?.pie_chart_sub_title,
      greenChemistryData?.dials?.pie_chart_sub_title,
      sustainablePackagingData?.dials?.pie_chart_sub_title
    ];
    // Helper functions to evaluate scenarios
    const hasPoorOrVeryPoor = scores.some((score) => score === "Poor" || score === "Very Poor");
    const allNoImprovement = scores.every((score) => score === "No Improvement");
    const hasGoodOrExcellent = scores.some((score) => score === "Good" || score === "Excellent");
    const remainingAreNoImprovement = scores.every(
      (score) => score === "No Improvement" || score === "Good" || score === "Excellent"
    );
  
    // Set the dial status based on the scenarios
    if (hasPoorOrVeryPoor || allNoImprovement) {
      setDialStatus("look_out");
    } else if (hasGoodOrExcellent && remainingAreNoImprovement) {
      setDialStatus("great_job");
    }
  }, [
    productEnvironmentalFootprintData?.dials?.pie_chart_sub_title,
      carbonFootprintData?.dials?.pie_chart_sub_title,
      greenChemistryData?.dials?.pie_chart_sub_title,
      sustainablePackagingData?.dials?.pie_chart_sub_title
  ]);

  return (
    <div className="pie_chart_main_div">
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {props.dials_without_data_show === "yes" ? (
          <div className="dials_without_data_div1">
            <div className="dials_without_data_div2">
              <div className="dials_without_data_div2_1">
                <img src={dials_without_data} alt="SIP" />
              </div>
            </div>
            <div className="dials_without_data_label1">Nothing here yet! </div>
            <div className="dials_without_data_label2">
              {props.dials_without_data_show_msg}
            </div>
          </div>
        ) : (
          <>
            {props.page === "product-assessment" ? (
              <div className="pie_chart_main_div_view_result">
                <button
                  style={{
                    cursor: "pointer",
                    border: "none",
                    backgroundColor: "transparent",
                    fontFamily: "kenvue-sans-regular",
                    color: "#000",
                  }}
                  onClick={() => handleCardClick(assessmentsData)}
                >
                  View All Results
                </button>
              </div>
            ) : (
              <div className="result_back_to_assessment">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      background: "none",
                      border: "none",
                    }}
                    onClick={() =>
                      navigate(
                        `/product-assessment/${assessmentsData.assessmentId}`
                      )
                    }
                  >
                    <img
                      src={result_arrow_full_small_left}
                      alt="Back Arrow"
                      style={{ marginRight: "8px", marginLeft: "-14px" }}
                    />Back to Assessment
                  </button>
                </div>
              </div>
            )}

            
              
              <div className="assessmentLabel">
              {dialStatus === "great_job" ? (
                <div
                  className="pie_chart_great_jon_div"
                >
                  <div>
                    <img src={great_job} alt="SIP"></img>
                  </div>
                  <div className="pie_chart_lock_out_label1">Great Job!</div>
                  <div className="pie_chart_lock_out_label2">
                    This product meets Kenvue's sustainable product innovation
                    commitment.
                  </div>
                </div>
              ) : (
                <div
                  className="pie_chart_lock_out_div"
                >
                  <div>
                    <img src={lock_out_warning} alt="SIP"></img>
                  </div>
                  <div className="pie_chart_lock_out_label1">Look out!</div>
                  <div className="pie_chart_lock_out_label2">
                    This product does not meet Kenvue's sustainable product
                    innovation commitment. Can you identify the driver(s) and
                    consider how we might improve the product's sustainability?
                  </div>
                </div>
              )}
                {productEnvironmentalFootprintSeries1 !== undefined && (
                  <PieChartDials
                    chartDivIndex="1"
                    title="Product Environmental Footprint"
                    sub_title={
                      productEnvironmentalFootprintData.dials
                        .pie_chart_sub_title
                    }
                    pie_chart_percentage={
                      productEnvironmentalFootprintData.dials
                        .pie_chart_percentage
                    }
                    data_series0={ProductEnvironmentalFootprint_series0}
                    data_series1={productEnvironmentalFootprintSeries1}
                    flipcard_description={
                      ProductEnvironmentalFootprintFlipcardDescription
                    }
                    selectedpiechart={
                      props.selectedtab === 1 ? "selected" : undefined
                    }
                  />
                )}

                {carbonFootprintSeries1 !== undefined && (
                  <PieChartDials
                    chartDivIndex="2"
                    title="Carbon Footprint"
                    sub_title={carbonFootprintData.dials.pie_chart_sub_title}
                    pie_chart_percentage={
                      carbonFootprintData.dials.pie_chart_percentage
                    }
                    data_series0={CarbonFootprint_series0}
                    data_series1={carbonFootprintSeries1}
                    flipcard_description={CarbonFootprintFlipcardDescription}
                    selectedpiechart={
                      props.selectedtab === 2 ? "selected" : undefined
                    }
                  />
                )}
                {greenChemistrySeries1 !== undefined && (
                  <PieChartDials
                    chartDivIndex="3"
                    title="Green Chemistry"
                    sub_title={greenChemistryData.dials.pie_chart_sub_title}
                    data_series0={GreenChemistry_series0}
                    pie_chart_percentage={
                      greenChemistryData.dials.pie_chart_percentage
                    }
                    data_series1={greenChemistrySeries1}
                    flipcard_description={GreenChemistryFlipcardDescription}
                    selectedpiechart={
                      props.selectedtab === 3 ? "selected" : undefined
                    }
                  />)}
                {sustainablePackagingSeries1 !== undefined && (
                  <PieChartDials
                    chartDivIndex="4"
                    title="Sustainable Packaging"
                    sub_title={
                      sustainablePackagingData?.dials?.pie_chart_sub_title ?? ""
                    }
                    data_series0={SustainablePackaging_series0}
                    pie_chart_percentage={
                      sustainablePackagingData?.dials?.pie_chart_percentage ??
                      ""
                    }
                    data_series1={sustainablePackagingSeries1}
                    flipcard_description={
                      SustainablePackagingFlipcardDescription
                    }
                    selectedpiechart={
                      props.selectedtab === 4 ? "selected" : undefined
                    }
                  />
                )}
              </div>
            
            {!assessmentsData.isPackagingDataCompleted && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <img src={dataInCompleteIcon} alt="Data incomplete" />
                <span
                  style={{
                    fontFamily: "kenvue-sans-regular",
                    fontSize: "16px",
                  }}
                >
                  There are one or more incomplete packaging components that are
                  not included in the results.
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DialsResultProductAssessment;
