import {createContext ,useContext } from "react";

export interface Post {
    _id: string;
    formulation: Formulation;
    brand?: (BrandEntity)[] | null;
    loggedInUserDetails: LoggedInUserDetails;
    packaging:Packaging;
  }
 
  export interface Formulation {
    netContent?: (string)[] | null;
    productionZone?: (string)[] | null;
    salesZone?: (string)[] | null;
    segment?: (SegmentEntity)[] | null;
    useDose?: (string)[] | null;
    useScenario?:(string)[] | null;
  }

  export interface SegmentEntity {
    productSegment: string;
    productSubSegment?: (string)[] | null;
  }
  
  export interface BrandEntity {
    brandName: string;
    shortBrandCode: string;
    longBrandName: string;
  }

  export interface LoggedInUserDetails {
    businessPhones?: (string)[] | null;
    displayName: string;
    givenName: string;
    jobTitle?: null;
    mail: string;
    mobilePhone?: null;
    officeLocation?: null;
    preferredLanguage?: null;
    surname: string;
    userPrincipalName: string;
    id: string;
    roles?: (string)[] | null;
    userName: string;
    accessToken: string;
  }

  interface DataType {
    name: string;
    type: string[];
   }

  export interface Packaging {
    componentType?: string[] | null;
    opacity?: string[] | null;
    color?: string[] | null;
    "pcr/pir/virgin"?: string[] | null;
    convertingProcess?: string[] | null;
    finishing_process?: string[] | null;
    packagingMaterial?:string[] | null;
    materials?:DataType[]|null;
    opacifier?:string[] | null;
  }

  export interface PostContextType {
    loaded: boolean;
    globaldata: Post[] | null;
    formulationData:Formulation;
    packagingData:Packaging;
    token:string|null;
  }

  const PostContext = createContext<PostContextType>({
    loaded: false,
    globaldata: [],
    formulationData:{},
    packagingData:{},  
    token: 'some_token_value'
  });

  const useGlobaldata = () => useContext(PostContext);

  export {PostContext,useGlobaldata};