import { useEffect,useState } from "react";
import axios from "axios"; 
import { getLoggedInUserDetails } from "@consumer/core-login-ui-mf";

const useFetch = <T,>(url: string): [boolean, T | null] => {
    const [fetchedData, setFetchedData] = useState<T | null>(null);
    const [loaded, setLoaded] = useState(false);
    const token=localStorage.getItem('userDetails')
    useEffect(() => {
     
      const getAllFetchedData = async () => {
        await axios
          .get(url,{headers:{'Authorization':`Bearer ${token}`}})
          .then((response) => {
            const data = response.data;
            const loggedInUserDetails = getLoggedInUserDetails();
            data[0]['loggedInUserDetails'] = loggedInUserDetails;
            if(data[0]['loggedInUserDetails']["givenName"]){
              sessionStorage.setItem('givenName',data[0]['loggedInUserDetails']["givenName"]);
              sessionStorage.setItem('mail',data[0]['loggedInUserDetails']["mail"]);
            }
            setFetchedData(data);
            setLoaded(true);
          })
          .catch((error) => console.error(`Error: ${error}`));
      };
  
      if( fetchedData === null ){ 
        getAllFetchedData();
      }
    }, [fetchedData,token,url]);
    
    return [loaded, fetchedData];
  };

  export default useFetch;