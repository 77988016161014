import { get, post } from ".";
import { IHeadersType } from "../models/Common.model";
import {
  ApiEndPointsURL,
  ApiEndPoints,
} from "./../constants/ApiEndPoints.constant";

const getApiHeaders = (): IHeadersType => ({
  "x-consumer-userId": "",
  "x-consumer-system": "",
  "x-consumer-correlationId": "test 1", // this needs to be handled from utility mf
  "x-consumer-timestamp": new Date().toISOString(), // this needs to be handled from utility mf
});

const apiBaseUrl = `${process.env.REACT_APP_UI_API_BASE_URL}`;
const apiBaseUrl_Local = ApiEndPointsURL;


export const getProductDetailsAllList = async (
  search: string,
  type: "all" | "myproduct"
) => {
  const apiUrl =
    search !== ""
      ? `${apiBaseUrl_Local}${ApiEndPoints.product_all_list}/search/${search}?type=${type}`
      : `${apiBaseUrl_Local}${ApiEndPoints.product_all_list}`;
  const headers = getApiHeaders();

  headers["x-consumer-userId"] = "";
  const productList = await get(apiUrl, headers);
  return productList;
};
export const getProductDetailsMyProduct = async (
  search: string,
  type: "all" | "myproduct"
) => {
  const apiUrl =
    search !== ""
      ? `${apiBaseUrl_Local}${ApiEndPoints.product_all_list}/search/${search}?type=${type}`
      : `${apiBaseUrl_Local}${ApiEndPoints.product_my_product}`;

  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const productMyList = await get(apiUrl, headers);
  return productMyList;
};
export const getProductDetailByID = async (id: string) => {
  const apiUrl = `${apiBaseUrl_Local}${ApiEndPoints.product_detail_by_id}${id}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const productDetails = await get(apiUrl, headers);
  return productDetails;
};

export const getProductAssessmentDetailByID = async (
  id: string,
  type: string
) => {
  const apiUrl = `${apiBaseUrl_Local}${ApiEndPoints.assessment_detail_by_id}${type}/${id}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const productDetails = await get(apiUrl, headers);
  return productDetails;
};
export const getAssessmentDetailBySipID = async (id: string) => {
  const apiUrl = `${apiBaseUrl_Local}${ApiEndPoints.assessment_detail_by_sip_id}${id}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const productDetails = await get(apiUrl, headers);
  return productDetails;
};
export const getRawMaterialDataByKeyword = async (
  searchTerm: string,
  page: number
) => {
  const apiUrl = `${apiBaseUrl_Local}${ApiEndPoints.get_rawMaterial}?limit=1000&page=${page}&initialValue=${searchTerm}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const searchSuggestions = await get(apiUrl, headers);
  return searchSuggestions;
};
export const getExperimentalDetails = async () => {
  const apiUrl = `${apiBaseUrl}${ApiEndPoints.experimental}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const experimetnalList = await get(apiUrl, headers);
  return experimetnalList;
};

export const getExperimentalAssementDetails = async (id: string) => {
  const apiUrl = `${apiBaseUrl}${ApiEndPoints.getExperimentalAssessment}${id}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const experimetnalAssessmentList = await get(apiUrl, headers);
  return experimetnalAssessmentList;
};

export const getProductAssessmentResultByID = async (
  product_id: string,
  assessment_id: string,
  type: string
) => {
  const apiUrl = `${apiBaseUrl_Local}${ApiEndPoints.calculate_result_by_id}/${type}/${product_id}/${assessment_id}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const productDetails = await get(apiUrl, headers);
  return productDetails;
};

export const getProductBaselineAssessmentResultByID = async (
  product_id: string,
  assessment_id: string,
  type: string
) => {
  const apiUrl = `${apiBaseUrl_Local}${ApiEndPoints.calculate_formulation_result_by_id}/${type}/${product_id}/${assessment_id}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const productDetails = await get(apiUrl, headers);
  return productDetails;
};

export const getUseDoseValue = async (
  productSegment: string,
  productSubSegment: string
) => {
  const apiUrl =
    productSegment !== "" &&
    productSubSegment !== "" &&
    `${apiBaseUrl_Local}${ApiEndPoints.get_useDose}`;
  const headers = getApiHeaders();
  headers["x-consumer-userId"] = "";
  const body = {
    productSegment: productSegment,
    productSubSegment: productSubSegment,
  };
  const useDoseValue = await post(apiUrl, headers, body);
  return useDoseValue;
};
