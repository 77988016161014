import React from "react";
import { TableCell, TableSortLabel, Typography, Box } from "@mui/material";

interface SortableTableHeaderProps<Row> {
  id: keyof Row;
  label: string;
  orderBy: keyof Row;
  order: "asc" | "desc";
  handleRequestSort: (property: keyof Row) => void;
  width?: string;
  infoIcon?: React.ReactNode;
  subLabel?: string;
  className?:string;
}

const SortableTableHeader = <Row,>({
  id,
  label,
  orderBy,
  order,
  handleRequestSort,
  infoIcon,
  subLabel,
  width = "auto",
  className = "",
}: SortableTableHeaderProps<Row>) => {
  return (
    <TableCell sx={{ width }} className={className} sortDirection={orderBy === id ? order : false}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold"}}
          >
            {label}
          </Typography>
          {subLabel && (
            <Typography
              className="packing-subLabel"
              style={{
                fontFamily: "kenvue-sans-regular",
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              {subLabel}
            </Typography>
          )}
        </Box>
        {infoIcon && <Box ml={1}>{infoIcon}</Box>}
        <TableSortLabel
          sx={{
            "&.Mui-active": {
              color: "theme.palette.primary.main",
              fontWeight: "bold", // Bold when active
            },
            "& .MuiTableSortLabel-icon": {
              opacity: 0.1, // Very low opacity by default
              transition: "opacity 0.3s, font-weight 0.3s", // Smooth transition for opacity and font weight
            },
            // Initial state styles
            "&:not(.Mui-active)": {
              color: "theme.palette.text.secondary", // Adjust the color as needed
              fontWeight: "normal", // Normal weight when not active
            },
            "&:hover .MuiTableSortLabel-icon": {
              opacity: 1, // Full opacity on hover
              fontWeight: "bold", // Bold on hover
            },
          }}
          active={orderBy === id}
          direction={orderBy === id ? order : "asc"}
          onClick={() => handleRequestSort(id)}
        />
      </Box>
    </TableCell>
  );
};

export default SortableTableHeader;
