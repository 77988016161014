export const ApiEndPoints = {
  getExperimentalAssessment: "/getProductExperimental?groupId=",
  experimental: `/experimental`,
  product_all_list: `/product`,
  product_my_product: `/product/myproduct`,
  product_detail_by_id: `/product/productDetails/`,
  assessment_delete: `/product/delete-assessment/`,
  assessment_add: `/product/add-assessment`,
  assessment_edit: `/product/edit-assessment`,
  assessment_detail_by_id: `/product/assessment/details/`,
  product_add: `/product/add-product`,
  product_edit: `/product/edit-product`,
  product_delete: `/product/delete/`,
  formula_codes: `/formula/formula-codes?initialLetters=`,
  formula_details: `/formula/formula-details/`,
  find_product: `/product-search/findProduct?initialLetters=`,
  find_product_details: `/product-search/findProduct/`,
  find_component: `/component-search/findComponent?initialLetters=`,
  find_component_details: `/component-search/findComponent/`,
  master_data: `/master-data`,
  add_member: `/product/add-member`,
  delete_member: `/product/delete-member/`,
  product_details: `/product/productDetails`,
  edit_member: "/product/edit-member/",
  add_packaging: "/product/assessment/add-packaging",
  add_update_packaging: "/product/assessment/add-update-packaging",
  edit_packaging: "/product/assessment/edit-packaging",
  delete_packaging: "/product/assessment/delete-packaging",
  add_update_formulation: "/product/assessment/add-update-formulation",
  get_rawMaterial: "/rm-search/findRawMaterial",
  get_useDose: "/segment/getUseDoseValue",
  calculate_result_by_id: "/calculate/result",
  calculate_formulation_result_by_id: "/calculate/formulation/result/",
  assessment_detail_by_sip_id: `/product/assessmentDetails/`,
};

export const ApiEndPointsURL = `${process.env.REACT_APP_API_BASE_URL}`;

export const ToastMessageNotificationTime = 2000;
