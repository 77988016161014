import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableSortLabel,
  Tooltip,
  IconButton,
  Box,
  TableBody,
  Typography,
  TableFooter,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Component, ComponentDetail } from "../../../structures/packaging";
import InfoIcon from "@mui/icons-material/Info";
import { ResultDataContext } from "../../../contexts/resultData/ResultDataContext";
import "../../../assets/css/table.scss";
import "../../../assets/css/SIP.css";
import { ExpandMore } from "@mui/icons-material";
import { sortData } from "../../../helper/GenericFunctions";
import { IGreenChemFormulationData, SortableTableHeaderProps } from "../sustainablePackaging/structure";

  interface DetailedTableProps {
  currentTab: string;
}

const SortableTableHeader: React.FC<SortableTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
  column,
}) => {
  const {
    id,
    label,
    align,
    colSpan = 1,
    rowSpan = 1,
    width,
    height,
    justifyContent='left',
    tooltipMessage,
    padding,
  } = column;

  const handleSort = () => {
    onRequestSort(id);
  };

  return (
    <TableCell
      align={align}
      colSpan={colSpan}
      rowSpan={rowSpan}
      sx={{
        position: "sticky",
        width: width ?? "339.75px",
        height: height ?? "96px",
        padding: padding ?? "12px 20px",
        border: "1px solid #E4E7EC",
        backgroundColor: "#F8F8F8",
        
      }}
    >
      <Box display="flex" alignItems="center" gap="8px" justifyContent={justifyContent}>

        <span style={{ whiteSpace: "nowrap" }}>{label}</span>
        {tooltipMessage && (
          <Tooltip title={tooltipMessage}>
            <IconButton size="small" className="info-icon" sx={{ ml: 1 }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <TableSortLabel
          active={orderBy === id}
          direction={order}
          onClick={handleSort}
          sx={{
            "&.Mui-active": {
              color: "theme.palette.primary.main",
              fontWeight: "bold",
            },
            "& .MuiTableSortLabel-icon": {
              opacity: 0.3,
              transition: "opacity 0.3s",
            },
            "&:hover .MuiTableSortLabel-icon": {
              opacity: 1,
            },
          }}
        >
        </TableSortLabel>
      </Box>
    </TableCell>
  );
};
const getHeaderColumns = (currentTab: string): Array<SortableTableHeaderProps["column"]> => {
  switch (currentTab) {
    case "PCR_CONTENT":
      return [
        { id: "componentName" as keyof Component, label: "Component Type", rowSpan: 2, width: "339.75px" },
        { id: "materialName" as keyof ComponentDetail, label: "Material Name", rowSpan: 2, width: "339.75px" },
        { id: "materialType" as keyof ComponentDetail, label: "Material Type", rowSpan: 2, width: "339.75px" },
      ];
    
    case "MATERIAL_EFFICIENCY":
      return [
        { id: "componentName" as keyof Component, label: "Component Type", rowSpan: 2, width: "339.75px" },
        { id: "materialName" as keyof ComponentDetail, label: "Material Name", rowSpan: 2, width: "339.75px" },
      ];
    
    case "RENEWABLE":
      return [
        { id: "rawMaterialTradeName" as keyof IGreenChemFormulationData, label: "RAW Material Trade Name", rowSpan: 2, width: "295px" },
        { id: "rawCode" as keyof IGreenChemFormulationData, label: "RAW Code", rowSpan: 2, width: "161px" },
      ];

    
  }
};
const getHeaderColumns2 = (currentTab: string): Array<SortableTableHeaderProps["column"]>=> {
  switch (currentTab) {
    case "MATERIAL_EFFICIENCY":
    case "PCR_CONTENT":
      return [
        {
          id: "baseLineComponentWeight" as keyof Component,
          label: "Weight (g)",
          justifyContent: "center",
          width: "205.09px",
          height: "60px",
          padding: "12px 18px",
        },
        {
          id: "baselineComponentWeightDose" as keyof Component,
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              {currentTab === "MATERIAL_EFFICIENCY"
                ? "Weight \n per dose (g)*"
                : "% PCR Content"}
            </span>
          ),
          width: "339.75px",
          height: "60px",
          padding: "12px 15px",
          justifyContent: "center",
          tooltipMessage:
            currentTab === "MATERIAL_EFFICIENCY"
              ? "Weight per dose (g)*"
              : "% PCR Content",
        },
        {
          id: "myProductComponentWeight" as keyof Component,
          label: "Weight (g)",
          justifyContent: "center",
          width: "205.09px",
          height: "60px",
          padding: "12px 18px",
        },
        {
          id: "myProductComponentWeightDose" as keyof Component,
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              {currentTab === "MATERIAL_EFFICIENCY"
                ? "Weight \n per dose (g)*"
                : "% PCR Content"}
            </span>
          ),
          width: "339.75px",
          height: "60px",
          justifyContent: "center",
          padding: "12px 15px",
          tooltipMessage:
            currentTab === "MATERIAL_EFFICIENCY"
              ? "Weight per dose (g)*"
              : "% PCR Content",
        },
      ];

    case "RENEWABLE":
      return [
        {
          id: "baselineWeight" as keyof IGreenChemFormulationData,
          label: "% w/w",
          justifyContent: "center",
          width: "110.09px",
          height: "42px",
          padding: "12px 18px",
        },
        {
          id: "baselineOrganic" as keyof IGreenChemFormulationData,
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              % organic
            </span>
          ),
          width: "160.09px",
          height: "42px",
          padding: "12px 15px",
          justifyContent: "center",
          tooltipMessage: "% organic",
        },
        {
          id: "baselineRenewable" as keyof IGreenChemFormulationData,
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              % renewable origin
            </span>
          ),
          width: "224.83px",
          height: "42px",
          padding: "12px 15px",
          justifyContent: "center",
          tooltipMessage: "% renewable origin",
        },
        {
          id: "myProductWeight" as keyof IGreenChemFormulationData,
          label: "% w/w",
          justifyContent: "center",
          width: "110.09px",
          height: "42px",
          padding: "12px 18px",
        },
        {
          id: "myProductOrganic" as keyof IGreenChemFormulationData,
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              % organic
            </span>
          ),
          width: "160.09px",
          height: "42px",
          padding: "12px 15px",
          justifyContent: "center",
          tooltipMessage: "% organic",
        },
        {
          id: "myProductRenewable" as keyof IGreenChemFormulationData,
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              % renewable origin
            </span>
          ),
          width: "224.83px",
          height: "42px",
          padding: "12px 15px",
          justifyContent: "center",
          tooltipMessage: "% renewable origin",
        },
      ];

   
  }
};
const DetailedTable = (props: DetailedTableProps) => {
  const { productEnvironmentalFootprintData, sustainablePackagingData,greenChemistryData } = useContext(ResultDataContext)
  const staticData = productEnvironmentalFootprintData?.packaging?.consumerPackaging;
  const totalData = sustainablePackagingData?.materialEfficiency?.barData;
  const totalPCRData = sustainablePackagingData?.pcrContent?.dialData;
  const totalRenewablePercent = greenChemistryData?.renewableOriginBonus?.totalPercent;
  const renewableData : IGreenChemFormulationData[] = greenChemistryData?.renewableOriginBonus?.robTableData ;
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({})
  const [outerSort, setOuterSort] = useState<{
    orderBy: keyof Component | keyof IGreenChemFormulationData;
    order: 'asc' | 'desc';
  }>({
    order: 'asc',
    orderBy: 'componentName' 
  });
  
  const [innerSort, setInnerSort] = useState<{ orderBy: keyof ComponentDetail, order: 'asc' | 'desc' }>({
    order: 'asc',
    orderBy: 'materialName'
  })

  const toggleRow = (id: number) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const headerColumns = getHeaderColumns(props.currentTab);
  const headerColumns2 = getHeaderColumns2(props.currentTab);

  const handleOuterSort = (property: keyof Component | keyof IGreenChemFormulationData) => {
    if (property === "baseLineComponentWeight" || property === "myProductComponentWeight" || property === "myProductComponentWeightDose" || property === "baselineComponentWeightDose" || property=== "rawMaterialTradeName"
      || property ==="rawCode"
      || property ==="baselineWeight"
      || property ==="baselineOrganic"
      || property ==="baselineRenewable"
      || property ==="myProductWeight"
      || property ==="myProductOrganic"
      || property ==="myProductRenewable") {
      setOpenRows({})
    }
    const isAsc = outerSort.orderBy === property && outerSort.order === 'asc'
    setOuterSort({ orderBy: property, order: isAsc ? 'desc' : 'asc' })
  }

  const handleInnerSort = (property: keyof ComponentDetail) => {
    const isAsc = innerSort.orderBy === property && innerSort.order === 'asc'
    setInnerSort({ orderBy: property, order: isAsc ? 'desc' : 'asc' })
  }

  const handleSortingRequest = (property: keyof Component | keyof ComponentDetail | keyof IGreenChemFormulationData) => {

    if (property === 'componentName' || property === "baseLineComponentWeight" || property === "myProductComponentWeight" || property === "myProductComponentWeightDose" || property === "baselineComponentWeightDose" || property=== "rawMaterialTradeName"
      || property ==="rawCode"
      || property ==="baselineWeight"
      || property ==="baselineOrganic"
      || property ==="baselineRenewable"
      || property ==="myProductWeight"
      || property ==="myProductOrganic"
      || property ==="myProductRenewable") {
      handleOuterSort(property)
    }
    if (property === 'materialName' ||property === "materialType") {
      handleInnerSort(property)
    }
  }

  
  const getFormattedValue = (
    currentTab: string,
    pcrValue: string | undefined,
    weightValue: string | undefined
  ): string => {
    const valueToFormat =
      currentTab === "PCR_CONTENT" ? pcrValue : weightValue;
    return valueToFormat !=null
      ? parseFloat(valueToFormat)?.toFixed(2).toString()
      : "";
  };

  const renderTotal = () => {
    const formatValue = (value: string) => (value ? parseFloat(value)?.toFixed(2) : "0.00");
  
    if (props.currentTab === "RECYCLE_READY" || props.currentTab === "PCR_CONTENT") {
      return (
        <TableRow className="result_detailed_table_div1">
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell style={{}} className="result_detailed_table_total">Total</TableCell>
          <TableCell style={{}} className="result_detailed_table_total_value">
            {formatValue(totalPCRData?.baseline)}
          </TableCell>
          <TableCell style={{}}>&nbsp;</TableCell>
          <TableCell style={{}} className="result_detailed_table_total_value">
            {formatValue(totalPCRData?.myproduct)}
          </TableCell>
        </TableRow>
      );
    }
  if(props.currentTab=="RENEWABLE"){
    return (
      <TableRow className="result_detailed_table_div1">
      <TableCell>&nbsp;</TableCell>
      <TableCell>&nbsp;</TableCell>
      <TableCell className="result_detailed_table_total" style={{ width: "90%", textAlign: "right" }}>
        Total
      </TableCell>
      <TableCell className="result_detailed_table_total_value" >
        {formatValue(totalRenewablePercent?.baselineOrganic)+'%'}
      </TableCell>
      <TableCell className="result_detailed_table_total_value">
      {formatValue(totalRenewablePercent?.baselineRenewable)+'%'}
      </TableCell>
      <TableCell className="result_detailed_table_total_value">
        
      </TableCell>
      <TableCell className="result_detailed_table_total_value">
      {formatValue(totalRenewablePercent?.myproductOrganic)+'%'}
      </TableCell>
      <TableCell className="result_detailed_table_total_value">
      {formatValue(totalRenewablePercent?.myproductRenewable)+'%'}
      </TableCell>
    </TableRow>
    )
  }
    return (
      <TableRow className="result_detailed_table_div1">
        <TableCell>&nbsp;</TableCell>
        <TableCell>&nbsp;</TableCell>
        <TableCell className="result_detailed_table_total" style={{ width: "45%", textAlign: "right" }}>
          Total
        </TableCell>
        <TableCell className="result_detailed_table_total_value">
          {formatValue(totalData?.baseline)}
        </TableCell>
        <TableCell>&nbsp;</TableCell>
        <TableCell className="result_detailed_table_total_value">
          {formatValue(totalData?.myproduct)}
        </TableCell>
      </TableRow>
    );
  };
  return (
      <TableContainer className="custom-table-container origin-bonus-table">
        <Table stickyHeader>
          <TableHead
            sx={{
              position: "sticky",
              "& th": {
                fontFamily: "kenvue-sans",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "150%",
                color: "#000",
              },
            }}
          >
            {/* First Row */}
            <TableRow className="header-row header-first-row">
              {headerColumns?.map((column) => (
                <SortableTableHeader
                  key={column.id}
                  order={outerSort.order}
                  orderBy={outerSort.orderBy}
                  onRequestSort={handleSortingRequest}
                  column={column}
                />
              ))}
              <TableCell
                align="center"
                sx={{
                  width: "462px",
                  backgroundColor: "#F8F8F8",
                  border: "1px solid #E4E7EC",
                }}
                colSpan={props.currentTab == "RENEWABLE" ? 3 : 2}
              >
                <span style={{ padding: "15px 0px" }}>Baseline Product</span>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  paddingLeft: "0px",
                  backgroundColor: "#F8F8F8",
                  border: "1px solid #E4E7EC",
                }}
                colSpan={props.currentTab == "RENEWABLE" ? 3 : 2}
              >
                My Product
              </TableCell>
            </TableRow>

            {/* Second Row */}
            <TableRow
              sx={{
                top: "36px",
                position: "sticky",
              }}
              className="header-second-row"
            >
              {headerColumns2?.map((column) => (
                <SortableTableHeader
                  key={column.id}
                  order={outerSort.order}
                  orderBy={outerSort.orderBy}
                  onRequestSort={handleSortingRequest}
                  column={column}
                />
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.currentTab == "RENEWABLE" &&
              sortData(
                [...renewableData],
                outerSort.orderBy as keyof IGreenChemFormulationData,
                outerSort.order
              ).map((row, index) => (
                <TableRow
                  hover
                  key={index + 1}
                  sx={{
                    cursor: "pointer",
                    "& td": {
                      border: "1px solid #e0e0e0", // Add border to each cell
                      padding: "16px 20px !important",
                      color: "#000",
                      fontFamily: "kenvue-sans-regular",
                      fontSize: "13.33px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "150%",
                    },
                  }}
                >
                  <TableCell className="table-cell-0">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ width: "100%" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          flexGrow: 1,
                          fontWeight: "400",
                          fontFamily: "kenvue-sans-regular",
                          fontSize: "14px",
                          color: "#000",
                          lineHeight: "18px",
                        }}
                      >
                        {row.rawMaterialTradeName}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell className="table-cell-1">{row.rawCode}</TableCell>

                  {/* For Renewable Tab */}
                  <TableCell sx={{ textAlign: "center" }} className="table-cell-2">
                    {row.baselineWeight?parseFloat(row.baselineWeight)?.toFixed(2):''}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }} className="table-cell-3">
                    {row.baselineOrganic ? parseFloat(row.baselineOrganic)?.toFixed(2) :''}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }} className="table-cell-4">
                    {row.baselineRenewable}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }} className="table-cell-5">
                    {row.myProductWeight ? parseFloat(row.myProductWeight)?.toFixed(2) :""}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }} className="table-cell-6">
                    {row.myProductOrganic ? parseFloat(row.myProductOrganic)?.toFixed(2):""}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }} className="table-cell-7">
                    {row.myProductRenewable}
                  </TableCell>
                </TableRow>
              ))}
            {props.currentTab !== "RENEWABLE" &&
              sortData(
                [...staticData],
                outerSort.orderBy as keyof Component,
                outerSort.order
              ).map((row, index) => (
                <React.Fragment key={index + 1}>
                  <TableRow
                    hover
                    sx={{
                      cursor: "pointer",
                      "& td": {
                        border: "1px solid #e0e0e0", // Add border to each cell

                        padding: "16px 20px !important",
                        color: "#000",
                        fontFamily: "kenvue-sans-regular",
                        fontSize: "13.33px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "150%",
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            flexGrow: 1,
                            fontWeight: "700",
                            fontFamily: "kenvue-sans",
                            fontSize: "14px",
                            color: "#000",
                            lineHeight: "18px",
                          }}
                        >
                          {row.componentName}
                          <IconButton
                            size="small"
                            aria-label="expand row"
                            data-testid="ExpandMoreIcon"
                            sx={{
                              ml: 1,
                              transform: openRows[index]
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s",
                            }}
                            onClick={() => toggleRow(index)}
                          >
                            <ExpandMore />
                          </IconButton>
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell></TableCell>
                    {props.currentTab === "RECYCLE_READY" ||
                    props.currentTab === "PCR_CONTENT" ? (
                      <TableCell></TableCell>
                    ) : (
                      ""
                    )}

                    <TableCell sx={{ textAlign: "center" }}>
                      {(row.baseLineComponentWeight
                        ? row.baseLineComponentWeight?.toFixed(2)
                        : ''
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {props.currentTab === "PCR_CONTENT"
                        ? (row.baselineComponentPCRContent?.toFixed(2) ?? '')
                        : (row.baselineComponentWeightDose?.toFixed(2) ?? '')}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {(row?.myProductComponentWeight?.toFixed(2) ?? '')}
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      {props.currentTab === "PCR_CONTENT"
                        ? (row?.myProductComponentPCRContent?.toFixed(2) ?? '')
                        : (row?.myProductComponentWeightDose?.toFixed(2) ?? '')}
                    </TableCell>
                  </TableRow>
                  {openRows[index] && (
                    <>
                      {sortData(
                        [...row.details],
                        innerSort.orderBy,
                        innerSort.order
                      ).map((detail, index) => (
                        <TableRow
                          key={index + 1}
                          sx={{
                            "& td": {
                              backgroundColor: "#F8F8F8",
                              border: "1px solid #E4E7EC", // Add border to each cell
                              padding: "16px 20px !important",
                              color: "#000",
                              fontFamily: "kenvue-sans-regular",
                              fontSize: "13.33px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "150%",
                            },
                          }}
                        >
                          <TableCell></TableCell>
                          <TableCell>{detail.materialName}</TableCell>
                          {props.currentTab === "RECYCLE_READY" ||
                          props.currentTab === "PCR_CONTENT" ? (
                            <TableCell>{detail.materialType}</TableCell>
                          ) : (
                            ""
                          )}
                          <TableCell sx={{ textAlign: "center" }}>
                            {detail.baselineMaterialWeight &&
                            parseFloat(detail.baselineMaterialWeight) !== null
                              ? parseFloat(
                                  detail.baselineMaterialWeight
                                )?.toFixed(2)
                              : ""}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {getFormattedValue(
                              props.currentTab,
                              detail.baselineMaterialPCRContent,
                              detail.baselineMaterialWeightDose
                            )}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {detail.myProductMaterialWeight &&
                            parseFloat(detail.myProductMaterialWeight) !== null
                              ? parseFloat(
                                  detail.myProductMaterialWeight
                                )?.toFixed(2)
                              : ""}
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {getFormattedValue(
                              props.currentTab,
                              detail.myProductMaterialPCRContent,
                              detail.myProductMaterialWeightDose
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
          </TableBody>
          <TableFooter>{renderTotal()}</TableFooter>
        </Table>
      </TableContainer>
  );
};
export default DetailedTable;
