import { useQuery, useMutation } from "react-query";
import {
  getProductDetailsAllList,
  getProductDetailByID,
  getProductAssessmentDetailByID,
  getProductAssessmentResultByID,
  getUseDoseValue,
  getRawMaterialDataByKeyword,
  getProductDetailsMyProduct,
  getAssessmentDetailBySipID,
  getProductBaselineAssessmentResultByID,
} from "../adapters/Api";

interface IRawMaterialObject {
  rawMaterialId: string;
  tradeName: string;
  percentage: string;
}

interface IGetUseDoseResponse {
  _id: string;
  "Product Segment": string;
  "Product Sub-Segment": string;
  "Use Dose / g": string | null;
  "Use Scenario": string | null;
  "Water Use / L": string | null;
  "Starting Temperature / C": string | null;
  "Finishing Temperature / C": string | null;
  "Evaporated Water / %": string | null;
  "Density / g/cm3": string | null;
}



export const useGetProductDetailsAllList = (
  vsearch: string,
  type: "all" | "myproduct"
) => {
  return useQuery(["ui-product-all-list"], () =>
    getProductDetailsAllList(vsearch, type)
  );
};

export const useGetProductDetailsMyProduct = (
  vsearch: string,
  type: "all" | "myproduct"
) => {
  return useQuery(["ui-product-my-list"], () =>
    getProductDetailsMyProduct(vsearch, type)
  );
};
export const useGetProductDetailByID = (id: string) => {
  return useQuery(["ui-product-detail-by-id"], () => getProductDetailByID(id));
};
export const useGetAssessmentDetailBySipID = (id:string) => {
  return useQuery(["ui-assessment-detail-by-sip-id"], () => getAssessmentDetailBySipID(id));
};
export const useGetProductAssessmentDetailByID = (id: string, type: string) => {
  return useQuery(["ui-product-assessment-detail-by-id"], () =>
    getProductAssessmentDetailByID(id, type)
  );
};

export const useGetUseDoseValue = (
  productSegment: string,
  productSubSegment: string
) => {
  // return useQuery(["ui-use-dose"], () =>getUseDoseValue(productSegment,productSubSegment))
  return useMutation<IGetUseDoseResponse>({
    mutationKey: ["ui-useDose", productSegment, productSubSegment],
    mutationFn: () => getUseDoseValue(productSegment, productSubSegment),
  });
};

export const useGetRawMaterialDataByKeyword = (
  searchTerm: string,
  page: number
) => {
  return useMutation<IRawMaterialObject[]>({
    mutationKey: ["ui-rawMaterial", searchTerm],
    mutationFn: () => getRawMaterialDataByKeyword(searchTerm, page),
  });
};

export const useGetProductAssessmentResultByID = (
  product_id: string,
  assessment_id: string,
  type: string
) => {
  return useQuery(["ui-product-assessment-result-by-id"], () =>
    getProductAssessmentResultByID(product_id, assessment_id, type)
  );
};

export const useGetBaselineTableResults = (
  product_id: string,
  assessment_id: string,
  type: string
) => {
  return useQuery(["ui-product-assessment-result-by-id"], () =>
    getProductBaselineAssessmentResultByID(product_id, assessment_id, type)
  );
};
