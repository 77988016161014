import React, { useEffect } from "react";
import ProductTabsComponent from "../components/dashboard/ProductTab.component";
import { useGetProductDetailsMyProduct } from "../hooks/UseGetProductDetails";
import { Context } from "../utills/useContext";
import { ProductComponentProp } from "../components/breadcrumb/types";

const ProductComponent: React.FC<ProductComponentProp> = (productValue) => {
  const { data, refetch } = useGetProductDetailsMyProduct(
    productValue.is_search,
    "myproduct"
  );

  useEffect(() => {
    refetch();
  }, [productValue, refetch]);

  return (
    <Context.Provider value={null}>
      <div
        style={{
          paddingBottom: "10px",
        }}
      >
        <div>
          <div className="product-tab" style={{ marginTop: "20px" }}>
            {data ? (
              <ProductTabsComponent
                product={data?.data}
                selectedValue={productValue.selectedValu}
                refetch={refetch}
              />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};

export default ProductComponent;
