// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/KenvueSansBold_normal_normal.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Kenvue_Sans-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Kenvue_Sans-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Kenvue_Sans-Extrabold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Kenvue_Sans-Semibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/KenvueSansRegular_normal_normal.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"kenvue-sans";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2")}@font-face{font-family:"kenvue-sans-bold";src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("otf")}@font-face{font-family:"kenvue-sans-medium";src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("otf")}@font-face{font-family:"kenvue-sans-extrabold";src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("otf")}@font-face{font-family:"kenvue-sans-semiBold";src:url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("otf")}@font-face{font-family:"kenvue-sans-regular";src:url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2")}body{font-family:"kenvue-sans",sans-serif}.header{color:"black";font-family:"kenvue-sans",sans-serif}.pdrm-ui{background:#f3f3f3;-ms-overflow-style:none;scrollbar-width:none;overflow-y:scroll}.pdrm-ui *{box-sizing:border-box}.pdrm-ui .homeContainer{display:flex;justify-content:space-between;align-items:center;padding:20px}.pdrm-ui .left-section{flex:1}.pdrm-ui .right-section{flex:1;display:flex;justify-content:flex-end;margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/assets/css/Style.scss","webpack://./src/assets/css/_variables.scss"],"names":[],"mappings":"AAEA,WACE,yBAAA,CACA,2DAAA,CAEF,WACE,8BAAA,CACA,yDAAA,CAEF,WACE,gCAAA,CACA,yDAAA,CAEF,WACE,mCAAA,CACA,yDAAA,CAEF,WACE,kCAAA,CACA,yDAAA,CAEF,WACE,iCAAA,CACA,2DAAA,CAEF,KACE,oCAAA,CAEF,QACE,aAAA,CACA,oCAAA,CAEF,SACE,kBClCiB,CDmCjB,uBAAA,CAEA,oBAAA,CAEA,iBAAA,CACA,WACE,qBAAA,CAGF,wBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,YAAA,CAEF,uBACE,MAAA,CAEF,wBACE,MAAA,CACA,YAAA,CACA,wBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
