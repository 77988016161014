import React from "react";
import result_hint_icon from "../assets/images/result_hint_icon.svg";
import BootstrapTooltipComponent from "../components/common/BootstrapTooltipComponent";
import "../assets/css/result-page.scss";
export const ResultHTMLContents1: React.FC = () => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="result_section1_sub_div1">
          <img src={result_hint_icon} alt="" />
        </div>
        <div className="result_section1_sub_label1">
          Which area should I focus on?
        </div>
      </div>
      <div className="result_section1_sub_label2">
        Explore each tab to dive deeper into your data and uncover valuable
        insights to drive informed decisions.
      </div>
    </>
  );
};

export const ResultHTMLContents2 = () => {
  return (
    <div>
      Please note that product comparisons exclude the Consumer Use phase.
      Results are displayed per functional unit (e.g. per dose of product).
    </div>
  );
};

export const ResultHTMLContents3 = (
  varCurrentTab: string,
  currentSection: string
) => {
  const getFootprintText = () => {
    if (varCurrentTab === "PRODUCT_ENVIRONMENTAL_FOOTPRINT") {
      switch (currentSection) {
        case "TOTAL_PRODUCT":
          return "product life cycle stages";
        case "FORMULATION":
          return "raw materials in the formulation";
        case "CONSUMER_PACKAGING":
          return "packaging components and materials";
        default:
          return "Product Environmental Footprint"; // default to general text if no match
      }
    } else {
      switch (currentSection) {
        case "TOTAL_PRODUCT":
          return "product life cycle stages";
        case "FORMULATION":
          return "raw materials in the formulation";
        case "CONSUMER_PACKAGING":
          return "packaging components and materials";
        default:
          return "Carbon Footprint"; // default to general text if no match
      }
    }
  };

  return (
    <div className="result1_section2_div1">
      
      { ((varCurrentTab === "PRODUCT_ENVIRONMENTAL_FOOTPRINT") && (currentSection === "FORMULATION" || currentSection === "CONSUMER_PACKAGING" )) ?
      <>Which {getFootprintText()} have the highest&nbsp;</> 
      :
      <>Which {getFootprintText()} have the highest&nbsp;{" "}</>
      }
      
      {varCurrentTab === "PRODUCT_ENVIRONMENTAL_FOOTPRINT"
        ? "Product Environmental Footprint"
        : "Carbon Footprint"}
      ?
      <span style={{ marginLeft: "5px" }}>
        <BootstrapTooltipComponent
          title={
            varCurrentTab === "PRODUCT_ENVIRONMENTAL_FOOTPRINT"
              ? "Product Environmental Footprint"
              : "Carbon Footprint"
          }
          subtitle={`Which ${getFootprintText()} have <br /> the highest ${
            varCurrentTab === "PRODUCT_ENVIRONMENTAL_FOOTPRINT"
              ? "Product Environmental Footprint"
              : "Carbon Footprint"
          }?`}
        />
      </span>
    </div>
  );
};
