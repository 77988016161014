// ProgressBarWithLabel.tsx
import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import {ProgressBarWithLabelProps} from "../../structures/formulation";

const ProgressBarWithLabel: React.FC<ProgressBarWithLabelProps> = ({ value, color, width = '90%' }) => {
  return (
    <Box display="flex" alignItems="center">
      <LinearProgress
        sx={{
          "--LinearProgress-thickness": "19px",
          width: width,
          background: "#D2D1D2",
          [`& .MuiLinearProgress-bar`]: {
            backgroundColor: color,
          },
        }}
        variant="determinate"
        value={value}
        className="linear-progress-bar"
      />
      <Typography variant="body2" className="progress-value">
        {`${value}`}
      </Typography>
    </Box>
  );
};

export default ProgressBarWithLabel;
