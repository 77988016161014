/* eslint-disable no-prototype-builtins */
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IconButton,
  Button,
  Stack,
  Avatar,
  AvatarGroup,
  CardContent,
  Chip,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Alert,
  AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { deepPurple } from "@mui/material/colors";
import Collapse from "@mui/material/Collapse";
import editicon from "../../assets/images/edit.svg";
import complete from "../../assets/images/complete.svg";
import incomplete from "../../assets/images/incomplete.svg";
import plusicon from "../../assets/images/add-icon.svg";
import addIcon from "../../assets/images/Add.svg";
import step_assessment from "../../assets/images/step_assessment.svg";
import "../../assets/css/SIP.css";
import "../../assets/css/Style.scss";
import "react-toastify/dist/ReactToastify.css";
import {
  ToastMessageNotificationTime,
} from "../../constants/ApiEndPoints.constant";
import PopupComponentAddMember from "../common/PopupComponentAddMember";
import ExperimentalAsseTabsComponent from "./ExperimentalAssement.component";
import { WARNING_MSG_DELETE_EXP_ASSESSMENT } from "../../constants/ExperimentalTooltip.constant";
import DeletePopupBox from "../modal/PopupComponentDelete";
import PopupAssessmentAdd from "../common/PopupComponentAddAssessment";
import LightTooltipComponent from "../common/LightTooltipComponent";
import { CommonBreadcrumb } from "./CommonBreadcrumb.component";
import { useGlobaldata } from "../../contexts/masterData/DataContext";
import { BootstrapTooltip } from "../../constants/Formula.constant";
import {
  ProductAssessmentProps,
  RowUsers,
  RowData,
} from "../../structures/products";
import {
  callDeleteAssessmentDetails,
  CheckCRUDAccess,
  formatDate,
  getAvatarLetters,
  GetToastContainer,
  truncate,
} from "../../helper/GenericFunctions";
import Header from "../common/Header";
import '../../assets/css/product-detail-page.scss'
import { Product } from "../../structures/allproduct";
import Popup from "../common/PopupComponentAddEditProduct";
import { TrackGoogleAnalyticsEvent } from "../common/TrackGoogleAnalyticsEvent";

interface AddTeamMember {
  productId?: string;
  userCRUDAccess?: number;
  users?: Array<RowUsers>;
}

interface CardAssessment {
  assessmentId: string;
  type: string;
}

interface AssessmentDelete {
  productID: string;
  productSipId: string;
  assessmentId: string;
  type: string;
}

const iconAriaControl = (isOpen) => (isOpen ? 'long-menu' : undefined);
const iconAriaExpanded = (isOpen): boolean | undefined => (isOpen ? true : undefined);
const getIconSrc = (completed) => (completed ? complete : incomplete);
const getCardHeight = (item) => (item ? 'auto' : '144px');
const getButtonColor = (item) => (item ? 'black' : 'grey');
const getIconFilter = (item) => (item ? '' : 'blur(2px)');

const ProductAssessment: React.FC<ProductAssessmentProps> = (props) => {
  const navigate = useNavigate();
  const productDetailData = props.productDetail[0];
  const ProductAssessmentExperimentalData =
    props?.productDetail[0]?.assessments?.experimental;
  const ProductAssessmentBaselineData =
    props?.productDetail[0]?.assessments?.baseline;
  const ProductAssessmentFinalData =
    props?.productDetail[0]?.assessments?.final;
  const userCRUDAccess = CheckCRUDAccess(
    productDetailData.users,
    "team_member"
  );
  const userCRUDAccess_assessment = CheckCRUDAccess(
    productDetailData.users,
    "assessment"
  );
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogProductID, setDialogProductID] = useState("");
  const [dialogProductSipID, setDialogProductSipID] = useState("");
  const { token } = useGlobaldata();

  const [step1Open, setStep1Open] = useState(true);
  const [step3Open, setStep3Open] = useState(true);
  const [initialAddmemberValue, setInitialAddmemberValue] =
    useState<AddTeamMember>({
      productId: "",
      userCRUDAccess: 0,
      users: [],
    });
  const assessmentId = productDetailData._id;
  const productSipIds = productDetailData?.productSipId;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const dialogKeyAssessmentAdd = 1;
  const dialogKeyDelete = 2;
  const dialogKey = 0;
  const dialogKeyProductEdit = 4;

  const [addAssessmentDialogOpen, setAddAssessmentDialogOpen] =
    useState<boolean>(false);

  const [dialogOpenProductEdit, setDialogOpenProductEdit] = useState<boolean>(false);  

  const [initialProductValues, setInitialProductValues] = useState<Product>({
    projectId: "",
    brandName: "",
    productName: "",
    description: "",
    projectName: "",
    _id: "",
  });

  const handleCloseAddAssessment = () => {
    setAddAssessmentDialogOpen(false);
  };

  const handleOpenAddPopup = (
    var_title: string,
    var_productId: string,
    var_productSipId: string
  ) => {
    setDialogTitle(var_title);
    setDialogProductID(var_productId);
    setDialogProductSipID(var_productSipId);
    setAddAssessmentDialogOpen(true);
  };

  const handleOpenDialog = (initialAddmemberValue: AddTeamMember) => {
    setInitialAddmemberValue(initialAddmemberValue);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const { globaldata } = useGlobaldata();
  const loginUserName = React.useMemo(() => {
    return (
      sessionStorage.getItem("givenName") ||
      globaldata?.[0]?.loggedInUserDetails?.givenName
    );
  }, [globaldata]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const [slcRowAss, setSlcRowAss] = useState<CardAssessment>({
    assessmentId: "",
    type: "",
  });

  const [initialValuesDeleteAss, setInitialValuesDeleteAss] =
    useState<AssessmentDelete>({
      productID: "",
      productSipId: "",
      assessmentId: "",
      type: "",
    });

  const handleMoreHorizClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData: RowData
  ) => {
    setSlcRowAss(rowData);
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDeletePopup = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    initialValuesDeleteAss: AssessmentDelete
  ) => {
    // Handle open delete popup action here
    ev.stopPropagation();
    setInitialValuesDeleteAss(initialValuesDeleteAss);
    setDeletePopupOpen(true);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const handleDeleteAss = async () => {
    if (token) {
      const response = await callDeleteAssessmentDetails(
        initialValuesDeleteAss,
        token
      );

      if (response === 204) {
        toast.success("Assessment details deleted successfully");
        setTimeout(() => {
          props.refetch();
          setDeletePopupOpen(false);
        }, ToastMessageNotificationTime);
      } else {
        toast.warning(
          "Error occured while deleting the assessment details, please try again!"
        );
        setDeletePopupOpen(false);
      }
      setDeletePopupOpen(false);
    }
  };

  const handleCloseDeletePopup = () => {
    // Handle close delete popup action here
    setDeletePopupOpen(false);
  };

  const handleOpenDialogEditProduct = (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    initialValues: Product
  ) => {
    ev.stopPropagation();
    setInitialProductValues(initialValues);
    setDialogOpenProductEdit(true);
  };

  const handleSubmit = () => {
    // Handle form submission here, for example, send the product data to your backend
  };

  const handleCloseDialogProductEdit = () => {
    setDialogOpenProductEdit(false);
  };
  const handleCardClick = async (
    productDetailData,
    ProductAssessmentBaselineData
  ) => {
    // Prevent navigation if there's a text selection
    if (window.getSelection().toString()) {
      return;
    }
  
    // Construct the navigation path
    const pagePath = `/product-assessment/${ProductAssessmentBaselineData.assessmentId}`;
  
    // Track the analytics event
    await TrackGoogleAnalyticsEvent("PAGE_VIEW", "Product Assessment", {
      loginUserName,
      PAGE_VIEW: pagePath,
    });
  
    // Navigate to the page
    navigate(pagePath, {
      state: {
        productID: productDetailData._id,
        productName: productDetailData.productName,
        productSipId: productDetailData.productSipId,
        experimentalName: ProductAssessmentBaselineData.name,
        experimentalID: ProductAssessmentBaselineData._id,
      },
    });
  };
  

  return (
    <>
      <div className="product-detail-main">
        <Header />
        <CommonBreadcrumb
          productID={productDetailData._id}
          experimentalID=""
          productName={productDetailData.productName}
          experimentalName=""
          path={window.location.pathname}
        />

       <div className="Product-detail-name-description">
       <div className="Product-detail-name-icon"
          
        >
          <div
            style={{
              width: "2%",
              float: "left",
            }}
            className="productEditIcon1"
          >
            {userCRUDAccess === 1 && (
              <button
                onClick={(e) => handleOpenDialogEditProduct(e, {
                  projectId: productDetailData?.projectId,
                  brandName: productDetailData?.brandName,
                  productName: productDetailData?.productName,
                  description: productDetailData?.description,
                  projectName: productDetailData?.projectName,
                  _id: productDetailData?._id
                })}
                style={{ background: "none", border: "none", alignItems:"center", display:"flex" }}
              >
                <img
                  alt="editicon"
                  src={editicon}
                  className="Product-detail-Image"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </button>
            )}
          </div>

          <div
           
            className="productEditTitle1">
            <span style={{ fontFamily: "kenvue-sans", fontSize: "39.8px" }}>
              {productDetailData.productName}
            </span>
          </div>

        </div>

        <div style={{  display: "flex" }}>
          <Stack direction="row" spacing={1}>
            <AvatarGroup
              max={4}
              spacing={-2}
              sx={{ "& .MuiAvatarGroup-avatar": { borderColor: "#BFBFBF" } }}
              componentsProps={{
                additionalAvatar: {
                  sx: {
                    backgroundColor: "#F8F8F8",
                    fontWeight: "bold",
                    color: "#000000",
                  },
                },
              }}
            >
              {productDetailData.users.map((row_user: { name?: string }) => (
                <Avatar
                  key={row_user.name}
                  sx={{
                    bgcolor: deepPurple[500],
                    height: "40.34px",
                    width: "40.34px",
                  }}
                  title={row_user.name}
                >
                  {getAvatarLetters(row_user.name)}
                </Avatar>
              ))}
            </AvatarGroup>
          </Stack>
          <button
            className="product-detail-manage-team"
            style={{ background: "none", border: "none" }}
            onClick={() => {
              handleOpenDialog({
                userCRUDAccess: userCRUDAccess,
              });
            }}
          >
            {"Manage team   >"}
          </button>
        </div>
        <div className="div-product">
    <div className="div-product-detail">
        <span className="product-detail-label1 header">Brand</span>
        <span className="product-detail-label2 header">{productDetailData.brandName}</span>

    </div>
    <div className="div-product-detail">
        <span className="product-detail-label1 header">SIP ID</span>
        <span className="product-detail-label2 header">{productDetailData.productSipId}</span>


    </div>
    <div className="div-product-detail">
        <span className="product-detail-label1 header">Project ID</span>
        <span className="product-detail-label2 header">{productDetailData.projectId === ""
            ? "N/A"
            : productDetailData.projectId}</span>


    </div>
    <div className="div-product-detail">
        <span className="product-detail-label1 header">Project Name</span>
        <span className="product-detail-label2 header">{productDetailData.projectName}</span>


    </div>
</div>

<div className="div-product" style={{ width: "860px"}}>
<div className="div-product-detail">
    <span className="product-detail-label1 header"> Product Description</span>
    <span className="product-detail-label2 header">{productDetailData.description === ""
      ? "N/A"
      : productDetailData.description}{" "}</span>
  </div>
  </div>
        {/* END CODE - DIV ROW1 */}

        
       </div>

        <br />
        <Divider />

        {/**START CODE - STEP1 MESSAGE */}

        {!props?.productDetail[0]?.assessments?.hasOwnProperty("baseline") && (
          <div>
            <Stack sx={{ width: "100%", paddingTop: "15px" }} spacing={2}>
              <Collapse
                in={step1Open}
                style={{ borderLeft: "4px solid #EDB600" }}
              >
                <Alert
                  icon={false}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setStep1Open(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="warning"
                >
                  <AlertTitle>
                    <div
                      style={{ display: "flex", width: "100%", height: "30px" }}
                    >
                      <div style={{ width: "10%" }}>
                        <img
                          alt="step_assessment"
                          src={step_assessment}
                          style={{ padding: "10px" }}
                        />
                      </div>
                      <div style={{ width: "80%" }}>
                        <span
                          style={{
                            fontFamily: "kenvue-sans",
                            fontSize: "19.2px",
                            fontWeight: "700",
                            color: "#000000",
                          }}
                        >
                          Step 1: Add your baseline assessment (Define)
                        </span>
                      </div>
                    </div>
                  </AlertTitle>
                  <span
                    style={{
                      fontFamily: "kenvue-sans-regular",
                      fontWeight: "400",
                      fontSize: "13.33px",
                      color: "#000000",
                      paddingLeft: "70px",
                    }}
                  >
                    This is the product that the innovation is either directly replacing or cannibalizing the most.
                  </span>
                </Alert>
              </Collapse>
            </Stack>
          </div>
        )}
        {/**END CODE - STEP1 MESSAGE */}

        {/**START CODE - STEP3 MESSAGE */}

        {props?.productDetail[0]?.assessments?.hasOwnProperty("baseline") &&
          props?.productDetail[0]?.assessments?.hasOwnProperty(
            "experimental"
          ) &&
          props?.productDetail[0]?.assessments?.experimental.length > 0 &&
          !props?.productDetail[0]?.assessments?.hasOwnProperty("final") && (
            <Stack sx={{ width: "100%", paddingTop: "15px" }} spacing={2}>
              <Collapse
                in={step3Open}
                style={{ borderLeft: "4px solid #EDB600" }}
              >
                <Alert
                  icon={false}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setStep3Open(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="warning"
                >
                  <AlertTitle style={{ width: "800px" }}>
                    <div
                      style={{ display: "flex", width: "100%", height: "30px" }}
                    >
                      <div style={{ width: "9%" }}>
                        <img
                          alt=""
                          src={step_assessment}
                          style={{ padding: "10px" }}
                        />
                      </div>
                      <div style={{ width: "80%" }}>
                        <span
                          style={{
                            fontFamily: "kenvue-sans",
                            fontSize: "19.2px",
                            fontWeight: "700",
                            color: "#000000",
                          }}
                        >
                          Step 3: Add your Final Product (Develop)
                        </span>
                      </div>
                    </div>
                  </AlertTitle>
                  <span
                    style={{
                      fontFamily: "kenvue-sans-regular",
                      fontSize: "13.33px",
                      color: "#000000",
                      paddingLeft: "70px",
                    }}
                  >
                    Generate final sustainability scores on the verified
                    product.
                  </span>
                </Alert>
              </Collapse>
            </Stack>
          )}

        {/**END CODE - STEP3 MESSAGE */}

        {/* Baseline and Final Assessment Start */}

        <div className="Baseline-Final-Section">
          <div className="Baseline-starts">
            <span className="Baseline-span-tag">
              Baseline Assessment
            </span>

            <LightTooltipComponent
              tooltipkey="1"
              title="Step 1:"
              subTitle="Add your baseline assessment (Define)"
              contents="This is the product that the innovation is either directly replacing or cannibalizing the most."
            />
          </div>

          <div className="Final-starts">
            <span className="Baseline-span-tag">
              Final Assessment
            </span>

            <LightTooltipComponent
              tooltipkey="2"
              title="Step 3:"
              subTitle="Add your Final Assessment (Develop)"
              contents="Generate final sustainability scores on the verified product."
            />
          </div>
        </div>

        {/* Final Assessment and Baseline Assessment Div Data */}

        {/**START CODE - BASELINE ASSESSMENT - VIEW | ADD */}

        <div className="Baseline-assessment">
          {ProductAssessmentBaselineData?.hasOwnProperty("_id")  ? (
            <div className="Baseline-data"
              key={ProductAssessmentBaselineData?._id}
            >
              <Grid className="BaselineCard-style"
                container
                spacing={1}
                style={{ marginTop: 0, // Reset margin-top
                  width: '100%', // Reset width
                  marginLeft: 0, // Reset margin-left
                  gridTemplateColumns: "repeat" }}
              >
                <Grid item xs={12} style={{ paddingLeft: "0px", paddingTop:"0px" }}>
                  <div className="Baseline-data-style">
                    <CardContent
                      className={"MuiCardContent-root"}
                      sx={{ cursor: "pointer", padding: "20px !important" }}
                      onClick={() =>
                        handleCardClick(productDetailData, ProductAssessmentBaselineData)
                      }
                    >
                      <Grid container spacing={2} style={{gap:"24px", padding:"20px 0px 0px 20px"}}>
                        <div className="Baseline-1">
                          <div className="Baseline-2">
                            <Chip
                              className="Baseline-3"
                              label="Baseline"
                              variant="outlined"
                            />
                            {ProductAssessmentBaselineData?.zone && (
                              <Chip
                                className="Baseline-4"
                                sx={{ backgroundColor: "#F8F8F8 !important"  }}
                                label={ProductAssessmentBaselineData?.zone}
                                variant="outlined"
                              />
                            )}
                            {ProductAssessmentBaselineData?.net_content && (
                              <Chip
                                className="Baseline-4"
                                sx={{ backgroundColor: "#F8F8F8 !important" }}
                                label={
                                  ProductAssessmentBaselineData?.net_content
                                }
                                variant="outlined"
                              />
                            )}
                          </div>

                          {userCRUDAccess_assessment === 1 && (
                            <div className="Baseline-5">
                              <IconButton
                              style={{padding:"0px"}}
                                aria-label="more"
                                id="long-button"
                                aria-controls={iconAriaControl(open1)}
                                aria-expanded={iconAriaExpanded(open1)}
                                aria-haspopup="true"
                                onClick={(e) =>
                                  handleMoreHorizClick(e, {
                                    assessmentId:
                                      ProductAssessmentBaselineData?._id,
                                    type: "Baseline",
                                  })
                                }
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <MoreHorizIcon
                                  className="Baseline-6"
                                />
                              </IconButton>
                              <Menu
                               className="Baseline-7"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                elevation={1}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <MenuItem
                                  onClick={(ev) =>
                                    handleOpenDeletePopup(ev, {
                                      productID: productDetailData?._id,
                                      productSipId:
                                        productDetailData?.productSipId,
                                      assessmentId:
                                        productDetailData?.assessments?.baseline
                                          ?._id,
                                      type: slcRowAss.type,
                                    })
                                  }
                                  className="menu_edit_delete_label1"
                                >
                                  Delete
                                  <DeleteIcon
                                    className="Baseline-8"
                                  ></DeleteIcon>
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                        </div>
                        <div className="Baseline-9">
                          <span className="Baseline-10">
                            {ProductAssessmentBaselineData?.name}
                          </span>
                        </div>

                        <div>
                        <div className="Baseline-11">
                          <span className="Baseline-12"
                          >
                            SIP ID :
                          </span>
                          <span className="Baseline-13">
                            {ProductAssessmentBaselineData?.assessmentId}
                          </span>
                        </div>
                        <div className="Baseline-14">
                          <span className="Baseline-12">
                            FG Spec :
                          </span>
                          <span className="Baseline-13">
                            {ProductAssessmentBaselineData?.fg_spec}
                          </span>
                        </div>
                        <div className="Baseline-14">
                          <span className="Baseline-12"
                          >
                            Formula Number :
                          </span>
                          <span className="Baseline-13">
                            {ProductAssessmentBaselineData?.formula_number}
                          </span>
                        </div>

                        <div className="Baseline-14" >
                          <span className="Baseline-12"
                          >
                            Lab Notebook Code :
                          </span>
                          <span className="Baseline-13">
                            {ProductAssessmentBaselineData?.lab_notebook_code}
                          </span>
                        </div>

                        <div className="Baseline-14">
                          <span className="Baseline-12">
                            PC Spec :
                          </span>
                          <BootstrapTooltip
                            className="BootstrapTooltip"
                            title={
                              <p className="BootstrapTooltip-p">
                                {ProductAssessmentBaselineData?.pc_spec}
                              </p>
                            }
                            placement="top"
                            arrow
                          >
                            <span className="Baseline-13"                            >
                              {truncate(
                                ProductAssessmentBaselineData?.pc_spec,
                                50
                              )}
                            </span>
                          </BootstrapTooltip>
                        </div>
                        </div>

                        <div className="Baseline-15">
                          <div className="Baseline-16">
                            <span className="Baseline-17">
                              Formula
                            </span>
                            <img
                              alt=""
                              src={getIconSrc(ProductAssessmentBaselineData?.isFormulationDataCompleted)}
                              style={{
                                marginLeft: "10px",
                              }}
                            />
                          </div>

                          <div className="Baseline-18">
                            <span
                              className="Baseline-17"
                            >
                              Packaging
                            </span>
                            <img
                              alt=""
                              src={getIconSrc(ProductAssessmentBaselineData?.isPackagingDataCompleted)}
                              style={{
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                        </div>

                        <div className="Baseline-19">
                          <span className="Baseline-20">
                            Date Modified:{" "}
                            {formatDate(
                              ProductAssessmentBaselineData?.updatedAt.substring(
                                0,
                                10
                              )
                            )}
                          </span>{" "}
                          |
                          <span className="Baseline-20">
                            &nbsp;Date Created:{" "}
                            {formatDate(
                              ProductAssessmentBaselineData?.createdAt.substring(
                                0,
                                10
                              )
                            )}
                          </span>
                        </div>
                      </Grid>
                    </CardContent>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid black",
                borderStyle: "dashed",
                height:getCardHeight(ProductAssessmentFinalData?.hasOwnProperty("_id")),
                letterSpacing: "4px",
                borderRadius: "32px 32px 32px 32px",
              }}
            >
              <span>
                <Button
                  onClick={() =>
                    handleOpenAddPopup(
                      "Baseline",
                      productDetailData?._id,
                      productDetailData?.productSipId
                    )
                  }
                  style={{
                    textDecoration: "none",
                    fontFamily: "kenvue-sans-regular",
                    fontWeight: "kenvue-sans-bold",
                    textTransform: "none",
                    color: getButtonColor(userCRUDAccess_assessment),
                    cursor: "pointer",
                  }}
                  endIcon={
                    <img
                      alt=""
                      src={plusicon}
                      style={{
                        filter: getIconFilter(userCRUDAccess_assessment),
                      }}
                    />
                  }
                  disabled={!userCRUDAccess_assessment}
                >
                  Add baseline assessment
                </Button>
              </span>
            </div>
          )}

          {/**END CODE - BASELINE ASSESSMENT - VIEW | ADD */}

          {/**START CODE - FINAL ASSESSMENT - VIEW | ADD */}

          {ProductAssessmentFinalData?.hasOwnProperty("_id") ? (
            <div className="Baseline-data"
              key={ProductAssessmentFinalData?._id}
            >
              {" "}
              <Grid className="BaselineCard-style"
                container
                spacing={1}
                style={{  marginTop: 0, // Reset margin-top
                  width: '100%', // Reset width
                  marginLeft: 0, // Reset margin-left
                  gridTemplateColumns: "repeat" }}
              >
                <Grid item xs={12} style={{paddingLeft:"0px", paddingTop:"0px"}}>
                  <div className="Baseline-data-style"
                  >
                    <CardContent
                      className={"MuiCardContent-root"}
                      sx={{ cursor: "pointer", padding: "20px !important" }}
                      onClick={() => {
                        if (window.getSelection().toString()) {
                          return;
                        }
                        navigate(
                          `/product-assessment/${ProductAssessmentFinalData.assessmentId}`,
                          {
                            state: {
                              ...{
                                productID: productDetailData._id,
                                productName: productDetailData.productName,
                                productSipId: productDetailData.productSipId,
                              },
                              ...{
                                experimentalName:
                                  ProductAssessmentFinalData.name,
                                experimentalID: ProductAssessmentFinalData._id,
                              },
                            },
                          }
                        );
                      }}
                    >
                      <Grid container spacing={2}style={{gap:"24px", padding:"20px 0px 0px 20px"}} >
                        <div className="Baseline-1">
                          <div className="Baseline-2">
                            <Chip className="Baseline-3"
                              sx={{backgroundColor:"#D3BDF2"}}
                              label="Final"
                              variant="outlined"
                            />
                            {ProductAssessmentFinalData.zone && (
                              <Chip
                                className="Baseline-4"
                                sx={{ backgroundColor: "#F8F8F8 !important" }}
                                label={ProductAssessmentFinalData.zone}
                                variant="outlined"
                              />
                            )}
                            {ProductAssessmentFinalData.net_content && (
                              <Chip 
                                className="Baseline-4"
                                sx={{ backgroundColor: "#F8F8F8 !important" }}
                                label={ProductAssessmentFinalData.net_content}
                                variant="outlined"
                              />
                            )}
                          </div>

                          {userCRUDAccess_assessment === 1 && (
                            <div className="Baseline-5"  >
                              <IconButton
                              style={{padding:"0px"}}
                                aria-label="more"
                                id="long-button"
                                aria-controls={iconAriaControl(open1)}
                                aria-expanded={iconAriaExpanded(open1)}
                                aria-haspopup="true"
                                onClick={(e) =>
                                  handleMoreHorizClick(e, {
                                    assessmentId:
                                      ProductAssessmentFinalData?._id,
                                    type: "Final",
                                  })
                                }
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <MoreHorizIcon className="Baseline-6"
                                />
                              </IconButton>
                              <Menu className="Baseline-7"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                elevation={1}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <MenuItem
                                  onClick={(ev) =>
                                    handleOpenDeletePopup(ev, {
                                      productID: productDetailData?._id,
                                      productSipId:
                                        productDetailData?.productSipId,
                                      assessmentId:
                                        productDetailData?.assessments?.final
                                          ?._id,
                                      type: slcRowAss.type,
                                    })
                                  }
                                  className="menu_edit_delete_label1"
                                >
                                  Delete
                                  <DeleteIcon className="Baseline-8"
                                   
                                  ></DeleteIcon>
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                        </div>

                        <div className="Baseline-9"
                        >
                          <span
                            className="Baseline-10"
                          >
                            {ProductAssessmentFinalData?.name}
                          </span>
                        </div>

                       <div>
                       <div className="Baseline-11"
                        >
                          <span
                            className="Baseline-12"
                          >
                            SIP ID :
                          </span>
                          <span className="Baseline-13"
                          >
                            {ProductAssessmentFinalData?.assessmentId}
                          </span>
                        </div>
                        <div className="Baseline-14"
                        >
                          <span className="Baseline-12"
                           
                          >
                            FG Spec :
                          </span>
                          <span className="Baseline-13"
                           
                          >
                            {ProductAssessmentFinalData?.fg_spec}
                          </span>
                        </div>
                        <div className="Baseline-14"
                        >
                          <span className="Baseline-12"
                            
                          >
                            Formula Number :
                          </span>
                          <span className="Baseline-13"
                          >
                            {ProductAssessmentFinalData?.formula_number}
                          </span>
                        </div>

                        <div className="Baseline-14" 
                        >
                          <span className="Baseline-12"
                          >
                            Lab Notebook Code :
                          </span>
                          <span className="Baseline-13"
                          >
                            {ProductAssessmentFinalData?.lab_notebook_code}
                          </span>
                        </div>

                        <div className="Baseline-14"
                           >
                          <span className="Baseline-12" >
                            PC Spec :
                          </span>
                          <BootstrapTooltip
                            className="BootstrapTooltip"
                            title={
                              <p className="BootstrapTooltip-p">
                                {ProductAssessmentFinalData?.pc_spec}
                              </p>
                            }
                            placement="top"
                            arrow
                          >
                            <span className="Baseline-13">
                              {truncate(
                                ProductAssessmentFinalData?.pc_spec,
                                50
                              )}
                            </span>
                          </BootstrapTooltip>
                        </div>
                       </div>

                        <div className="Baseline-15" >
                          <div className="Baseline-16" >
                            <span className="Baseline-17" >
                              Formula
                            </span>{" "}
                            <img
                              alt=""
                              src={getIconSrc(ProductAssessmentFinalData?.isFormulationDataCompleted)}
                              style={{
                                marginLeft: "10px",
                              }}
                            />
                          </div>

                          <div className="Baseline-18" >
                            <span className="Baseline-17" >
                              Packaging
                            </span>{" "}
                            <img
                              alt=""
                              src={getIconSrc(ProductAssessmentFinalData?.isPackagingDataCompleted)}
                              style={{
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                        </div>

                        <div className="Baseline-19">
                          <span className="Baseline-20" >
                            Date Modified:{" "}
                            {formatDate(
                              ProductAssessmentFinalData.updatedAt.substring(0, 10)
                            )}
                          </span>{" "}
                          |
                          <span className="Baseline-20" >
                            &nbsp;Date Created:{" "}
                            {formatDate(
                              ProductAssessmentFinalData.createdAt.substring(0, 10)
                            )}
                          </span>
                        </div>
                      </Grid>
                    </CardContent>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid black",
                borderStyle: "dashed",
                height: getCardHeight(ProductAssessmentBaselineData?._id),
                borderRadius: "32px 32px 32px 32px",
              }}
            >
              <span>
                <Button
                  onClick={() =>
                    handleOpenAddPopup(
                      "Final",
                      productDetailData?._id,
                      productDetailData?.productSipId
                    )
                  }
                  style={{
                    fontFamily: "kenvue-sans-regular",
                    fontWeight: "kenvue-sans-bold",
                    textTransform: "none",
                    color: getButtonColor(userCRUDAccess_assessment),
                    cursor: "pointer",
                  }}
                  endIcon={
                    <img
                      alt=""
                      src={plusicon}
                      style={{
                        filter: getIconFilter(userCRUDAccess_assessment),
                      }}
                    />
                  }
                  disabled={!userCRUDAccess_assessment}
                >
                  Add final assessment
                </Button>
              </span>
            </div>
          )}
        </div>

        {/**END CODE - FINAL ASSESSMENT - VIEW | ADD */}

        <br />
        <Divider />
        <br />

        <div className="Baseline-24"> 
          <Button
            style={{
              fontSize: "16px",
              height: "54px",
              fontWeight: "400",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingTop: "13px",
              paddingBottom: "13px",
              width: "auto",
              backgroundColor: userCRUDAccess_assessment ? "#000000" : "gray",
              color: "white",
              borderRadius: "24px",
              border: "1px",
              float: "right",
              textTransform: "none",
              fontFamily: "kenvue-sans-regular",
              cursor: "pointer",
            }}
            endIcon={
              <img
                alt=""
                style={{ height: "24px", width: "24px" }}
                src={addIcon}
              />
            }
            onClick={() =>
              handleOpenAddPopup(
                "Experimental",
                productDetailData?._id,
                productDetailData?.productSipId
              )
            }
            disabled={!userCRUDAccess_assessment}
          >
            {"Add Experimental Assessment"}
          </Button>
        </div>


       
      </div>
      <ExperimentalAsseTabsComponent
          ExperimentalData={ProductAssessmentExperimentalData}
          varproductData={{
            productID: productDetailData._id,
            productName: productDetailData.productName,
            productSipId: productDetailData.productSipId,
          }}
          refetch={props.refetch}
          varUserCRUDAccess={userCRUDAccess_assessment}
        />
      <PopupAssessmentAdd
        key={dialogKeyAssessmentAdd}
        open={addAssessmentDialogOpen}
        onClose={handleCloseAddAssessment}
        title={dialogTitle}
        productID={dialogProductID}
        ProductSipID={dialogProductSipID}
        refetch={props.refetch}
      />

      <PopupComponentAddMember
        initialValues={initialAddmemberValue}
        key={dialogKey}
        open={dialogOpen}
        onClose={handleCloseDialog}
        productId={assessmentId}
        productSipId={productSipIds}
        refetch={props.refetch}
      />

      <DeletePopupBox
        key={dialogKeyDelete}
        open={deletePopupOpen}
        onClose={handleCloseDeletePopup}
        onDelete={handleDeleteAss}
        dialogTitle="Warning"
        dialogContent={WARNING_MSG_DELETE_EXP_ASSESSMENT}
      />

      <Popup
        key={dialogKeyProductEdit}
        open={dialogOpenProductEdit}
        onClose={handleCloseDialogProductEdit}
        onSubmit={handleSubmit}
        initialValues={initialProductValues}
        refetch={props.refetch}
      />

      <GetToastContainer />
    </>
  );
};

export default ProductAssessment;
