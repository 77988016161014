// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#panel1-header {
    display: flex !important;
    padding: 24px !important;
}
.packaging-save-btn {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family:"kenvue-sans-regular",sans-serif;
    top: 20px;
    height: 53px;
    padding: 10px;
    margin-top: 5px;
    background-color: #000;
    color: #fff;
    border-radius: 30px;
    border: 1px solid black;
}
.calculating-text{
    font-family: "kenvue-sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
}
.MuiAccordionSummary-content{
    margin: 0px !important;
}
.MuiAccordionDetails-root{
    padding: 8px 24px 24px !important;
}



`, "",{"version":3,"sources":["webpack://./src/components/consumer-packaging-tab/packaging.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,wBAAwB;AAC5B;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,4CAA4C;IAC5C,SAAS;IACT,YAAY;IACZ,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,sCAAsC;IACtC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,iCAAiC;AACrC","sourcesContent":["#panel1-header {\n    display: flex !important;\n    padding: 24px !important;\n}\n.packaging-save-btn {\n    font-size: 16px;\n    line-height: 20px;\n    font-weight: 400;\n    font-family:\"kenvue-sans-regular\",sans-serif;\n    top: 20px;\n    height: 53px;\n    padding: 10px;\n    margin-top: 5px;\n    background-color: #000;\n    color: #fff;\n    border-radius: 30px;\n    border: 1px solid black;\n}\n.calculating-text{\n    font-family: \"kenvue-sans\", sans-serif;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 150%;\n    color: #000000;\n}\n.MuiAccordionSummary-content{\n    margin: 0px !important;\n}\n.MuiAccordionDetails-root{\n    padding: 8px 24px 24px !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
