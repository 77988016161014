import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { useGetProductAssessmentDetailByID } from "../../hooks/UseGetProductDetails";
import { FormulationDataType } from "../../structures/formulation";
import {
  PackagingDataType,
  PackagingLevelData,
} from "../../structures/packaging";

export interface ProductDataProps {
  brandName: string;
  productId: string;
  productName: string;
  productSipId: string;
  user?: Array<UserDataType>;
  details?: AssessmentDataType;
}
export interface ProductContextProp {
  productData: ProductDataType;
  usersData: UserDataType[] | null;
  refetch: () => void;
  assessmentsData: AssessmentDataType;
  formulation: FormulationDataType | null;
  primaryPackaging: PackagingLevelData | null;
  secondaryPackaging: PackagingLevelData | null;
  assessmentsType?: string | null;
  packagingData: PackagingDataType | null;
  fetchingDataInProgress: boolean;
  isBaselinePresent: boolean;
  isBaselineDataComplete: boolean;
}

interface ProductProviderProps {
  children: ReactNode;
  assessmentId: string;
  assessmentType: string;
}

// Create the context
export const ProductDataContext = createContext<ProductContextProp>({
  productData: {
    productId: "",
    productName: "",
    brandName: "",
    productSipId: "",
  },
  usersData: null,
  refetch: () => {},
  assessmentsData: {
    assessmentId: "",
    name: "",
    _id: "",
  },
  formulation: null,
  primaryPackaging: null,
  secondaryPackaging: null,
  assessmentsType: null,
  packagingData: null,
  fetchingDataInProgress: false,
  isBaselinePresent: false,
  isBaselineDataComplete:false
});
// Initial values
interface AssessmentDataType {
  assessmentId: string;
  name: string;
  _id: string;
  isFormulationDataCompleted?: boolean;
  isPackagingDataCompleted?: boolean;
  isFormulationCalculated?: boolean;
  isPackagingCalculated?: boolean;
  fg_spec?: string;
  formula_number?: string;
  lab_notebook_code?: string;
  pc_spec?: string;
  sku_erp_code?: string;
  zone?: string;
  net_content?: string;
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  packaging_level?: PackagingLevelData[] | null;
  formulation?: FormulationDataType | null;
}

interface ProductDataType {
  productId: string;
  productName: string;
  brandName: string;
  productSipId: string;
}

interface UserDataType {
  name: string;
  role: string;
  mail: string;
}

// Create a provider component

export const ProductDataProvider: React.FC<ProductProviderProps> = ({
  children,
  assessmentType,
  assessmentId,
}) => {
  const [formulation, setFormulation] = useState<FormulationDataType | null>(
    null
  );
  const [packagingData, setPackagingData] = useState<PackagingDataType | null>(
    null
  );
  const [primaryPackaging, setPrimaryPackaging] =
    useState<PackagingLevelData | null>(null);
  const [secondaryPackaging, setSecondaryPackaging] =
    useState<PackagingLevelData | null>(null);
  const [productData, setProductData] = useState<ProductDataType>({
    productId: "",
    productName: "",
    brandName: "",
    productSipId: "",
  });
  const [varIsBaselinePresent, setVarIsBaselinePresent] =
    useState<boolean>(false);
    const [varIsBaselineDataComplete, setVarIsBaselineDataComplete] =
    useState<boolean>(false);
  const [usersData, setUsersData] = useState<UserDataType[]>([]);
  const [assessmentsData, setAssessmentsData] = useState<AssessmentDataType>({
    assessmentId: "",
    name: "",
    _id: "",
  });

  const {
    data,
    refetch,
    isLoading: fetchingDataInProgress,
  } = useGetProductAssessmentDetailByID(assessmentId, assessmentType);

  const updatePackagingStates = (packagingLevels: PackagingLevelData[]) => {
 
    const primaryPackaging =
      packagingLevels.find((level) => level.packaging_level === "Primary") ||
      null;
    const secondaryPackaging =
      packagingLevels.find((level) => level.packaging_level === "Secondary") ||
      null;
     if (primaryPackaging) {
        primaryPackaging.components.forEach((component) => {
          component.material = component.material.map((material, index) => ({
            ...material,
      _id: index,
          }));
        });
      }
      if (secondaryPackaging) {
        secondaryPackaging.components.forEach((component) => {
          component.material = component.material.map((material, index) => ({
            ...material,
      _id: index,
          }));
        });
      }
    setPrimaryPackaging(primaryPackaging);
    setSecondaryPackaging(secondaryPackaging);
  };

  const findProductData = useCallback(
    (product: ProductDataProps) => {
      setProductData({
        productId: product.productId,
        productName: product.productName,
        brandName: product.brandName,
        productSipId: product.productSipId,
      });
      product.user && setUsersData(product.user);
    },
    []
  );

  const findAssessmentById = useCallback(
    (details: AssessmentDataType) => {
      if (details) {
        setAssessmentsData(details);
        const formulation = details.formulation ? details.formulation : null; // added this modification to auto fill exsisting data in baseline and final asssessments as response from server is little different JFUX-449
        setFormulation(formulation);
        if (details.packaging_level) {
          setPackagingData({ packaging_level: details.packaging_level });
          updatePackagingStates(details.packaging_level);
        }
      }
      // If not found
      return null;
    },
    []
  );

  useEffect(() => {
    if (data) {
      const product = data[0];
      findProductData(product);
      if (assessmentId) {
        findAssessmentById(product.details);
        setVarIsBaselinePresent(product.isBaselinePresent);
        setVarIsBaselineDataComplete(product.isBaselineDataComplete);
      }
    }
  }, [data, assessmentId,findAssessmentById,findProductData]);

  useEffect(() => {
    refetch();
  }, [assessmentId,refetch]);

  const value = useMemo(() => {
    return {
      productData,
      usersData,
      refetch,
      assessmentsData,
      formulation,
      primaryPackaging,
      secondaryPackaging,
      assessmentsType: assessmentType,
      packagingData,
      fetchingDataInProgress,
      isBaselinePresent: varIsBaselinePresent,
      isBaselineDataComplete:varIsBaselineDataComplete
    };
  }, [
    productData,
    usersData,
    refetch,
    assessmentsData,
    formulation,
    primaryPackaging,
    secondaryPackaging,
    assessmentType,
    packagingData,
    fetchingDataInProgress,
    varIsBaselinePresent,
    varIsBaselineDataComplete
  ]);

  return (
    <ProductDataContext.Provider value={value}>
      {children}
    </ProductDataContext.Provider>
  );
};
