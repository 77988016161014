export const FML_NUMBER_TOOLTIP_CONTENT = `Use the ‘Import Formula’ functionality to import a
formulation directly from Concerto`;

export const NET_CONTENT_TOOLTIP_CONTENT = `The total weight or volume of the product included in one
pack i.e. the size claimed on the label.
`;

export const COMPONENT_TYPE = `The packaging component type. For example, bottle, cap, jar, jump.`
export const COMPONENT_WEIGHT = `The total weight of the packaging component. For example, the total weight of a bottle, or the total weight of a cap.`
export const OPACITY = `An indicator of how much a material/component can diffuse light, ranging from low (e.g. clear, translucent) to high (e.g. opaque). `
export const COLOR_TYPE =`The color of the packaging component. For example, black, white, blue. `
export const OPACIFIERS =`Identify whether any substances have been added to the packaging component to make it opaque.  `

export const PRODUCT_ZONE_TOOLTIP_CONTENT = `The region in which the product will be manufactured. If the
product will be manufactured in multiple regions or the
production zone is currently unknown, please select
‘Global’.`;

export const LEAF_ICON_CONTENT=`Raw material is from a renewable origin - may qualify for bonus`

export const WARNING_ICON_CONTENT=`Raw material contains a 'Watch List' ingredient - will receive penalty`

export const SALES_ZONE_TOOLTIP_CONTENT = `
The region in which the product will be sold. If the product will be sold in multiple regions or the sales zone is currently unknown, please select ‘Global’. `;

export const PRODUCT_SEGMENT_TOOLTIP_CONTENT = `
The product segment describes the product family and the primary benefit that the product delivers. Only products with the same product segment should be compared in the SIP. `;

export const PRODUCT_SUB_SEGMENT_TOOLTIP_CONTENT = `
The product sub-segment is used to assign a use dose and use scenario (e.g. rinse off, leave on, ingested) to the product. It predominantly describes the format of product delivery. `;

export const USE_DOSE_TOOLTIP_CONTENT = `The Use Dose is used to define the Functional Unit used to measure and compare a product’s footprint. For Essential Health and Skin Health & Beauty products, the Use Dose is a single dose. For a Self-Care product, the Use Dose is defined as the maximum dose that a consumer can take in a 24-hour period. The Use Dose is automatically populated based on the Product Segmentation. The value can be overwritten but this should only be done if evidence can be provided to support the new Use Dose. `;

export const CONSUMABLE_USED_TOOLTIP_CONTENT = `Please capture if the consumer use instructions indicate the use of a cotton pad to use the product. 1 cotton pad = 1 consumable used. This field will carry a default value of 0. `;

export const WARNING_MSG = `You are editing the material composition of a formula. These changes are only captured in the SIP tool for simulation purposes. These changes will NOT reflect back in Concerto. The formula code will no longer be attached to this experimental product.`;

export const USE_SCENARIO_TOLTIP_CONTENT = `The use scenario describes the way in which the product is used by the consumer e.g., Rinse off – the product is applied then rinsed off with hot water.`

export const NEW_CONSUMER_MSG = `You are editing the material composition of a component. These changes are only captured in the SIP tool for simulation purposes. These changes will NOT reflect back in TRU. The PC spec will no longer be attached to this experimental product.`

export const WARNING_MSG_DELETE_EXP_ASSESSMENT = `Sustainable Innovation Profiler will permanently delete this assessment. Do you wish to continue?`;

export const WARNING_MSG_DELETE_PRODUCT = `Sustainable Innovation Profiler will permanently delete this product`;

export const WARNING_MSG_DELETE_PACKAGING_COMPONENT = `Sustainable Innovation Profiler will permanently delete this packaging component. Do you wish to continue?`;

export const PRODUCT_ENVIRONMENTAL_FOOTPRINT_TOOLTIP = "Leverages Life Cycle Assessment (LCA) methodology to measure the total environmental impact of a product.";

export const CARBON_FOOTPRINT_FOOTPRINT_TOOLTIP = "The carbon footprint of a raw material is the total greenhouse gas emissions, particularly carbon dioxide (CO2), generated during its extraction, production, and transportation. It quantifies the material's contribution to climate change.";

export const GREEN_CHEMISTRY_FOOTPRINT_TOOLTIP = "The Green Chemistry score encourages formulations that minimize or eliminate harmful substances for both human health and the environment. It consists of three pillars: the GAIA score, Watch List score, and Renewable Origin index of raw materials. Scores range from 0 to 100, with 100 representing the best performance.";

export const WARNING_MSG_CANCEL = "Are you sure you want to cancel? Any unsaved progress will be lost";

export const PRODUCT_EVACUATION=`The Product Evacuation or Restitution Rate is the share of product that can be removed from the packaging by the consumer.`;

export const WARNING_MSG_CHANGE_VALUE=`You are editing the material composition of a component. These changes are only captured in the SIP tool for simulation purposes. These changes will NOT reflect back in TRU. The PC spec
will no longer be attached to this experimental assessment product`

export const PC_SPEC=` PC Spec Use the 'Import Component' functionality to import a
                    packaging component directly from TRU.`