import { MenuItem } from "@mui/material";
import * as React from "react";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import GridviewCard from "../common/GridViewComponentProduct";
import ListviewCard from "../common/ListViewComponentProduct";
import "../../assets/css/Style.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGlobaldata } from "../../contexts/masterData/DataContext";
import { ExperimentalDataItem } from "../breadcrumb/types";
import { AllProductDetails } from "../../structures/allproduct";
import { productSortFunction } from "./helper";
import "../../assets/css/index.scss";

const ProductTabsComponent: React.FC<AllProductDetails> = (data) => {
  const { globaldata } = useGlobaldata();
  const loginUserEmail = sessionStorage.getItem("mail")
    ? sessionStorage.getItem("mail")
    : globaldata?.[0].loggedInUserDetails.mail;
  const [hasMore, setHasMore] = useState(true);
  const [value, setValue] = useState("Modified Date");
  const [gridViewOpen, setGridViewOpen] = useState<boolean>(true);
  const [listViewOpen, setListViewOpen] = useState<boolean>(false);
  const [items, setItems] = useState<ExperimentalDataItem[]>([]);
  const [visibleItems, setVisibleItems] = useState(6);
  const [sortValue, setSortValue] = useState<string | null>(null);

  useEffect(() => {
    if (sortValue === null) {
      setItems(data?.product?.slice(0, visibleItems));
    } else {
      let copyArrayvalue: ExperimentalDataItem[] = [];
      copyArrayvalue = productSortFunction(data?.product, sortValue);
      setItems(copyArrayvalue);
    }
  }, [visibleItems, data?.product?.length, data, sortValue]);
  const loadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4);

    items.length > 0 ? setHasMore(true) : setHasMore(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    setSortValue(event.target.value);
    let copyArrayvalue: ExperimentalDataItem[] = [];
    copyArrayvalue = productSortFunction(data?.product ?? [], sortValue);
    setItems(copyArrayvalue);
  };

  useEffect(() => {
    if (value === "Modified Date") {
      const copyArrayvalue: ExperimentalDataItem[] = [...data.product]
        .sort((a, b) => {
          const convertDate = a?.updatedAt ?? ""; //.replace(/(st|nd|rd|th)/, "");
          const convertDateB = b?.updatedAt ?? ""; //.replace(/(st|nd|rd|th)/, "");
          return (
            new Date(convertDate).getTime() - new Date(convertDateB).getTime()
          );
        })
        .reverse();
      setItems(copyArrayvalue.slice(0, visibleItems));
    }
  }, [data?.product, value, visibleItems]);

  const GridViewOpenClick = () => {
    if (gridViewOpen) {
      setGridViewOpen(false);
    }
    setGridViewOpen((prevState) => !prevState);
    setListViewOpen(false);
  };

  const ListViewOpenClick = () => {
    if (listViewOpen) {
      setListViewOpen(false);
    }
    setListViewOpen((prevState) => !prevState);
    setGridViewOpen(false);
  };

  return (
    <>
       <div className="product-tab-sort-row">
        <div style={{  display: "flex" }}>
          <h2 style={{ fontSize: "33px", fontFamily: "kenvue-sans", marginTop:"7px" }}>
            {"My Products"}
          </h2>
        </div>

      <div style={{display: "flex", alignItems:"center", gap:"16px", marginBottom:"16px"}}>
        {/* GridView Open and Close */}
        <div style={{display: "flex", alignItems:"center" }}>
          <div className="all_my_product_sort_grid_list_label1"
            style={{
            }}
          >
            Product Sort:
          </div>
          <div style={{ }} >
            <FormControl
              sx={{
                m: 0,
                border: "none",
                "& fieldset": {
                  border: "none",
                },
              }}
            >
              <Select
                style={{ height: "28px", border: "none", fontFamily: "kenvue-sans-regular" }}
                value={value}
                IconComponent={ExpandMoreIcon}
                onChange={handleChange}
                // className="all_my_product_sort_grid_list_label1"
              >
                <MenuItem value={"Modified Date"}>Modified Date</MenuItem>

                <MenuItem value="A-Z">A-Z</MenuItem>

                <MenuItem value={"Created Date"}>Created Date</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{}}>
          {gridViewOpen ? (
            <button
              onClick={GridViewOpenClick}
              style={{
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                border: "none",
                textDecorationColor: "#6CC24A",
                borderBottom: "2px solid #00B097",
                borderWidth: "7%",
                marginRight: "auto",
                float: "right",
                background: "none",

                padding: 0,
              }}
            >
             
              <AppsIcon style={{ marginLeft: "0px" }} />
              <span style={{ }} className="all_my_product_sort_grid_list_label1"><b> Grid View</b></span>
            </button>
          ) : (
            <button
              onClick={GridViewOpenClick}
              style={{
                marginLeft: "5px",
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                marginRight: "auto",
                float: "right",
                borderBottomStyle: "none",
                background: "none",
                border: "none",
                padding: 0,
             
              }}
            >
           
              <AppsIcon style={{ marginLeft: "0px" }} />{" "}
              <span style={{  }} className="all_my_product_sort_grid_list_label1"> Grid View</span>
            </button>
          )}
        </div>

        {/* ,ListView Open and Close */}
        <div style={{}}>
          {listViewOpen ? (
            <button
              onClick={ListViewOpenClick} 
              className="list-view"

              style={{
               
                float: "right",
                cursor: "pointer",
                marginLeft: "0px",
                alignItems: "center",
                display: "flex",
                background: "none",
                border: "none",
                padding: 0,
                textDecorationColor: "#6CC24A",
                borderBottom: "2px solid #00B097",
                borderWidth: "71%",
              }}
            >
              <FormatListBulletedIcon />
              <span style={{}} className="all_my_product_sort_grid_list_label1"> <b>List View</b></span>
            </button>
          ) : (
            <button
              onClick={ListViewOpenClick}
              className="list-view"
              style={{
                
                float: "right",
                cursor: "pointer",
                alignItems: "center",
                textDecoration: "none",
                borderBottomStyle: "none",
                display: "flex",
                background: "none",
                border: "none",
                padding: 0,
              }}
            >
              <FormatListBulletedIcon />
              <span style={{}} className="all_my_product_sort_grid_list_label1"> List View</span>
            </button>
          )}
        </div>
      </div>
      </div>
      <div style={{width:"75px", display:"contents"}}>
      <span
        style={{
          fontFamily: "kenvue-sans-regular",
          fontWeight: "400",
          fontSize: "13.33px",
        }}
      >
        {data.product.length} Products
      </span></div>

      {data.product.length <= 0 && (
        <div className="product_no_result_label">Nothing to see here yet!</div>
      )}
      <InfiniteScroll
        dataLength={items?.length}
        next={loadMore}
        hasMore={hasMore}
        loader={""}
      >
        {gridViewOpen ? (
          <div style={{ top: "20px", marginTop: "10px" }}>
            <GridviewCard
              props={items}
              refetch={data.refetch}
              pageRouter="myproduct"
              sort_order={value}
              loggedInUserEmail={loginUserEmail}
            />
          </div>
        ) : (
          <div style={{ top: "20px", marginTop: "10px",paddingRight:"24px" }}>
            <ListviewCard
              props={items}
              refetch={data.refetch}
              pageRouter="myproduct"
              sort_order={value}
              loggedInUserEmail={loginUserEmail}
            />
          </div>
        )}
      </InfiniteScroll>
    </>
  );
};

export default ProductTabsComponent;
